import React, { useState, useContext, useEffect } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { GoSearch } from "react-icons/go";

import classes from "./ProjectDetails.module.css";
import Records from "../RecordTab/records";
import Request from "../RequestTab/Request";
import Reports from "../ReportTab/reports";

import { projectContext, userIdContext } from "../../pages/Home";

import {
  getDatabase,
  set,
  ref,
  onValue,
  get,
  child,
  on,
  remove,
  off,
} from "firebase/database";
import { db } from "../../Firebase/config";

export default function ProjectDetails(props) {
  const project = useContext(projectContext); // context coming from home component
  const { userNumber, userName } = useContext(userIdContext); // context coming from home component

  const [role, setRole] = useState();
  const [selectedTab, setSelectedTab] = useState("record");
  const [searchEntries, setSearchEntries] = useState("");

  const [sharedPeople, setSharedPeople] = useState();

  const [tags, setTags] = useState();

  useEffect(() => {
    if (searchEntries) {
      setSearchEntries("");
    }

    if (project) {
      let path = `userDetails/${userNumber}/projects/${project.id}/role`;
      onValue(
        ref(db, path),
        (snapshot) => {
          if (snapshot.exists()) {
            let tempRole = snapshot.val();
            console.log("role from records :", tempRole);
            setRole(tempRole);
          } else {
            console.log("No users found");
            setRole();
          }
        },
        (error) => {
          console.log("error while fetching users", error);
        }
      );

      // shared people details fetching
      let sharedPeoplepath = `${project.id}/sharedPeople`;
      onValue(
        ref(db, sharedPeoplepath),
        (snapshot) => {
          if (snapshot.exists()) {
            let usersData = snapshot.val();
            setSharedPeople(usersData);
          } else {
            console.log("No users found");
            setSharedPeople();
          }
        },
        (error) => {
          console.log("error while fetching users", error);
        }
      );

      // fetching tags
      let tagsPath = `userDetails/${userNumber}/myTags/`;
      onValue(
        ref(db, tagsPath),
        (snapshot) => {
          if (snapshot.exists()) {
            let tagsData = snapshot.val();
            setTags(tagsData);
          } else {
            console.log("No tags found");
            setTags();
          }
        },
        (error) => {
          console.log("error while tags", error);
        }
      );
    }
  }, [project]);

  if (!project) {
    return (
      <div className={classes.project_select_alert}>
        <p className={classes.alert_note}>Please select the project</p>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.navTabs_wrapper}>
        <div className={classes.navTabs}>
          <p
            className={`${classes.tabBtn} ${
              selectedTab === "record" ? classes.active : ""
            }`}
            onClick={() => setSelectedTab("record")}
          >
            Record
          </p>
          <p
            className={`${classes.tabBtn} ${
              selectedTab === "request" ? classes.active : ""
            }`}
            onClick={() => setSelectedTab("request")}
          >
            Request
          </p>
          <p
            className={`${classes.tabBtn} ${
              selectedTab === "report" ? classes.active : ""
            }`}
            onClick={() => setSelectedTab("report")}
          >
            Report
          </p>
          <div className={classes.inputWrapper}>
            <input
              type="text"
              placeholder="Search..."
              value={searchEntries}
              className={classes.searchInputTab}
              onChange={(e) => setSearchEntries(e.target.value)}
            />
            <GoSearch className={classes.searchIcon} />
          </div>
          <p className={classes.tabBtn}>
            <PiDotsThreeVerticalBold />
          </p>
        </div>
      </div>

      {role && (
        <div className={classes.tabContent}>
          {selectedTab === "record" && (
            <Records
              searchEntries={searchEntries}
              role={role}
              setRole={setRole}
              sharedPeople={sharedPeople}
              tags={tags}
            />
          )}
          {selectedTab === "request" && (
            <Request
              searchEntries={searchEntries}
              role={role}
              setRole={setRole}
              sharedPeople={sharedPeople}
              tags={tags}
            />
          )}
          {selectedTab === "report" && (
            <Reports
              searchEntries={searchEntries}
              role={role}
              setRole={setRole}
            />
          )}
        </div>
      )}
    </div>
  );
}
