import React, { createContext, useEffect, useState } from "react";
import Switch from "react-switch";
import classes from "./Home.module.css";
import { FaPlus } from "react-icons/fa6";
import Chat from "../components/Chat/Chat";
import Projects from "../components/Projects/Projects";
import ChatScreen from "../components/Chat/ChatScreen";
import ProjectDetails from "../components/Projects/ProjectDetails";
import Createproject from "../modal/createProject";
import loading from "../assets/imgs/loading.gif";
import { fetchProjects, fetchUserName } from "../api/api";
import { fetchConversations } from "../api/api";
import { useLocation } from "react-router-dom";
import { encryptHelper } from "../encryption/encryptDecrypt";
import { IoMdChatboxes } from "react-icons/io";
import { LuIndianRupee } from "react-icons/lu";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
export const projectContext = createContext();

export const userIdContext = createContext();

export default function Home() {
  const navigate = useNavigate();

  const location = useLocation();

  const { isAuthenticated, mobileNumber } = location.state || {};

  const userNumber = encryptHelper.encode(mobileNumber);

  const [userName, setUserName] = useState();
  const [role, setRole] = useState();
  useEffect(() => {}, []);

  const [screenLoading, setScreenLoading] = useState(true);

  const [switchedToChat, setSwitchedToChat] = useState(false);

  const [projectCreationModalOpen, setProjectCreationModalOpen] =
    useState(false);

  const [allProjects, setAllprojects] = useState([]);

  const [selectedProject, setSelectedProject] = useState();
  console.log("selectedProject :", selectedProject);

  const [selectedChat, setSelectedChat] = useState();
  const [chatData, setChatData] = useState([]);

  const [chats, setChats] = useState(null);

  useEffect(() => {
    console.log("isAuthenticated from home from useEffect : ", isAuthenticated);
    if (!isAuthenticated) {
      navigate("/", { replace: true });
      return null;
    }
  });

  useEffect(() => {
    const retrieveProjects = (data) => {
      let projectsArray = [];
      Object.keys(data).forEach((projectId) => {
        projectsArray.push(data[projectId]);
      });
      projectsArray.reverse();
      console.log("projects array from home page :", projectsArray);

      // let sortedProject = projectsArray.sort(
      //   (a, b) => b.lastUpdated - a.lastUpdated
      // );
      setAllprojects(projectsArray);
      // setSelectedProject(projectsArray[0]);
      setScreenLoading(false);
    };

    const getProjects = async () => {
      try {
        let path = `userDetails/${userNumber}/projects`;
        await fetchProjects(path, retrieveProjects);
      } catch (error) {
        console.log(error);
        setScreenLoading(false);
      }
    };

    getProjects();
    getUserName();
  }, [userNumber]);

  useEffect(() => {
    let path = "conversations/" + userNumber + "/";
    fetchConversations(path, setChats);
  }, []);

  const getUserName = async () => {
    const retrieveUserName = (data) => {
      setUserName(data.UserName);
    };
    let path = `userDetails/${userNumber}/personalInfo`;
    try {
      await fetchUserName(path, retrieveUserName);
    } catch (error) {
      console.log(error);
    }
  };

  const logoutHandler = () => {
    localStorage.clear();
    // window.history.replaceState({}, '', window.location.href);
    navigate("/", {
      replace: true,
    });
  };

  return (
    <>
      {screenLoading ? (
        <div className={classes.loading}>
          <img src={loading} alt="loading" />
          <p>Loading... Please wait</p>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={classes.left_class}>
              {!switchedToChat ? (
                <div className={classes.addProjBtn_biMenu}>
                  <p
                    className={classes.addProjBtn}
                    style={{ position: "relative" }}
                    onClick={() => setProjectCreationModalOpen(true)}
                  >
                    Add new project{" "}
                    <span
                      style={{
                        paddingLeft: "10px",
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      <FaPlus size={20} color="#fff" />
                    </span>
                  </p>
                  <div>
                    <FiLogOut
                      size={25}
                      color="#fff"
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        top: "5px",
                      }}
                      title="Logout"
                      onClick={logoutHandler}
                    />
                  </div>
                </div>
              ) : null}

              {/* <div className={classes.addProjBtn_biMenu}>
                <p
                  className={classes.addProjBtn}
                  style={{
                    opacity: switchedToChat ? 0 : 1,
                    position: "relative",
                    bottom: "8px",
                  }}
                  onClick={() => setProjectCreationModalOpen(true)}
                >
                  Add new project{" "}
                  <span
                    style={{
                      paddingLeft: "10px",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    <FaPlus size={20} color="#fff" />
                  </span>
                </p>

                <div>
                  <FiLogOut
                    size={25}
                    color="#fff"
                    style={{ cursor: "pointer", marginRight: "15px" }}
                    title="Logout"
                    onClick={logoutHandler}
                  />
                </div>
              </div> */}

              <div>
                <>
                  {switchedToChat ? (
                    <Chat
                      selectedChat={selectedChat}
                      setSelectedChat={setSelectedChat}
                      chats={chats}
                      userId={userNumber}
                      chatData={chatData}
                      setChatData={setChatData}
                      role={role}
                      setRole={setRole}
                    />
                  ) : (
                    <Projects
                      allProjects={allProjects}
                      selectedProject={selectedProject}
                      setSelectedProject={setSelectedProject}
                      userId={userNumber}
                      role={role}
                      setRole={setRole}
                    />
                  )}
                </>
                <div className={classes.chat_Icon}>
                  {switchedToChat ? (
                    <LuIndianRupee
                      size={25}
                      onClick={() => setSwitchedToChat(false)}
                    />
                  ) : (
                    <IoMdChatboxes
                      size={25}
                      onClick={() => setSwitchedToChat(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.right_class}>
              <div>
                {switchedToChat ? (
                  <ChatScreen
                    selectedChat={selectedChat}
                    setSelectedChat={setSelectedChat}
                    chats={chats}
                    userId={userNumber}
                    chatData={chatData}
                    setChatData={setChatData}
                    role={role}
                    setRole={setRole}
                  />
                ) : (
                  <userIdContext.Provider value={{ userNumber, userName }}>
                    <projectContext.Provider value={selectedProject}>
                      <ProjectDetails role={role} setRole={setRole} />
                    </projectContext.Provider>
                  </userIdContext.Provider>
                )}
              </div>
            </div>
          </div>

          {projectCreationModalOpen && (
            <Createproject
              projectCreationModalOpen={projectCreationModalOpen}
              setProjectCreationModalOpen={setProjectCreationModalOpen}
              userId={userNumber}
              userName={userName}
              setSelectedProject={setSelectedProject}
              role={role}
              setRole={setRole}
            />
          )}
        </>
      )}
    </>
  );
}
