import { useEffect, useState } from "react";
import classes from "./Entries.module.css";
import { fetchEntries } from "../../api/api";
import { Oval } from "react-loader-spinner";
import { GrAttachment } from "react-icons/gr";
import EntryAttachments from "../../modal/entryAttachments";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import {
  userDetailsUpdate,
  transactionUpdate,
  deleteHandler,
} from "../../api/api";
import updateValues from "../../Firebase/firebase";

export default function Entries(props) {
  const {
    userId,
    project,
    projectId,
    searchEntries,
    selectedDataRange,
    entrySelectionHandler,
    sharedPeople,
    userName,
    allEntries,
    setAllEntries,
    displayReceived,
    displaySpent,
    role,
    entriesData,
    setEntriesData,
    setRole,
  } = props;

  const [spinnerShow, setSpinnerShow] = useState(true);

  const [selectedAttachment, setSelectedAttachment] = useState({
    isSelected: false,
    details: {},
  });

  const [entryMenuClicked, setEntryMenuClicked] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    setSpinnerShow(true);

    if (selectedDataRange.startDate && selectedDataRange.endDate) {
      let startDate = Number(selectedDataRange.startDate);
      let endDate = Number(selectedDataRange.endDate);

      let data = {};
      if (Object.keys(allEntries)?.length > 0) {
        Object.keys(allEntries).forEach((timestamp) => {
          let stamp = Number(timestamp);

          let date = new Date(stamp).getDate();
          date = date < 10 ? `0${date}` : date;

          let month = new Date(stamp).getMonth() + 1;
          month = month < 10 ? `0${month}` : month;

          let year = new Date(stamp).getFullYear();

          let yyyymmdd = Number(`${year}${month}${date}`);

          if (startDate === endDate) {
            if (startDate === yyyymmdd) {
              data[stamp] = allEntries[stamp];
            }
          } else if (startDate <= yyyymmdd && yyyymmdd <= endDate) {
            data[stamp] = allEntries[stamp];
          }
        });
        setEntriesData(data);
        setSpinnerShow(false);
      } else {
        setEntriesData(data);
        setSpinnerShow(false);
      }
    } else {
      setEntriesData(allEntries);
      setSpinnerShow(false);
    }
  }, [selectedDataRange, allEntries]);

  useEffect(() => {
    if (projectId && userId) {
      const retrieveEntries = (data) => {
        // setEntriesData(data);
        setAllEntries(data);
        setSpinnerShow(false);
      };
      const getEntries = async () => {
        setSpinnerShow(true);
        try {
          let path = `${projectId}/SE-pettycash/records/transactionHistory`;
          await fetchEntries(path, retrieveEntries);
        } catch (error) {
          console.log("error on entries page :", error);
          setSpinnerShow(false);
        }
      };

      getEntries();
    }
  }, [projectId]);

  const entryDeleteHandler = (data) => {
    let finance = { ...project.finances };
    if (data.type === "credit") {
      finance["balance"]["amount"] =
        Number(finance["balance"]["amount"]) - Number(data.amount);
      finance["received"]["amount"] =
        Number(finance["received"]["amount"]) - Number(data.amount);
    } else {
      finance["balance"]["amount"] =
        Number(finance["balance"]["amount"]) + Number(data.amount);

      finance["spent"]["amount"] =
        Number(finance["spent"]["amount"]) - Number(data.amount);
    }

    if (sharedPeople) {
      Object.keys(sharedPeople).forEach((mobNo) => {
        let userDetails_financePath = `userDetails/${mobNo}/projects/${project.id}/finances`;
        userDetailsUpdate(userDetails_financePath, finance);
      });
    } else {
      let userDetails_financePath = `userDetails/${userId}/projects/${project.id}/finances`;
      userDetailsUpdate(userDetails_financePath, finance);
    }

    let financepath = `${project.id}/SE-pettycash/finances`;
    transactionUpdate(financepath, finance);

    let entrydeletePath = `${project.id}/SE-pettycash/records/transactionHistory/${data.stamp}`;
    deleteHandler(entrydeletePath);

    const lastUpdatedData = {
      date: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: new Date().getTime(),
    };

    let lastUpdatedPath = `${project.id}/SE-pettycash/lastUpdated`;
    transactionUpdate(lastUpdatedPath, lastUpdatedData);

    const lastTimeStamp = new Date().getTime();
    let userDetails_lastUpdatePath = `userDetails/${userId}/projects/${project.id}/`;
    userDetailsUpdate(userDetails_lastUpdatePath, {
      lastUpdated: lastTimeStamp,
    });

    setEntryMenuClicked({
      status: false,
      id: null,
    });
  };
  const calculateBalances = async (recordsData) => {
    let totalSpent = 0;
    let totalReceived = 0;
    const transactionHistory = allEntries;

    for (const transaction of Object.values(transactionHistory)) {
      if (transaction.type === "debit") {
        totalSpent += Number(transaction.amount);
      } else {
        totalReceived += Number(transaction.amount);
      }
    }

    const balance = totalReceived - totalSpent;
    console.log("balance", balance);
    console.log("totalSpent", totalSpent);
    console.log("totalReceived", totalReceived);
    if (
      Number(project.finances.spent.amount) != totalSpent ||
      Number(project.finances.received.amount) != totalReceived ||
      Number(project.finances.balance.amount) != balance
    ) {
      const finances = {
        spent: {
          amount: totalSpent,
          attribute: {
            name: project.finances.spent.attribute
              ? project.finances.spent.attribute.name
              : "Spent",
          },
        },
        received: {
          amount: totalReceived,
          attribute: {
            name: project.finances.received.attribute
              ? project.finances.received.attribute.name
              : "Received",
          },
        },
        balance: {
          amount: balance,
          attribute: {
            name: project.finances.balance.attribute
              ? project.finances.balance.attribute.name
              : "Balance",
          },
        },
      };
      console.log("finances inside calculateBalance ", finances);
      await updateValues(
        "POST",
        projectId + "/SE-pettycash/finances/",
        finances
      );
      console.log("sharedPeople inside calculateBalance ", sharedPeople);
      if (sharedPeople?.length > 0) {
        console.log("sharedPeople inside calculateBalance ", sharedPeople);
        for (let i = 0; i < sharedPeople.length; i++) {
          await updateValues(
            "POST",
            "userDetails/" +
              sharedPeople[i] +
              "/projects/" +
              projectId +
              "/finances/",
            finances
          );
        }
      } else {
        await updateValues(
          "POST",
          "userDetails/" + userId + "/projects/" + projectId + "/finances/",
          finances
        );
      }
    }
    return {
      totalSpent,
      totalReceived,
      balance,
    };
  };
  useEffect(() => {
    if (displayReceived || displaySpent) {
      console.log(
        "displayReceived or displaySpent is true",
        displayReceived,
        displaySpent
      );
    } else {
      if (Object.keys(allEntries).length > 0) {
        calculateBalances(allEntries);
      }
    }
  }, [allEntries]);
  const transactionTypeChangeHandler = (data) => {
    let finance = { ...project.finances };
    console.log("finance from transactionTypeHandler :", finance);
    if (data.type === "credit") {
      finance["balance"]["amount"] =
        Number(finance["balance"]["amount"]) - 2 * Number(data.amount);
      finance["received"]["amount"] =
        Number(finance["received"]["amount"]) - Number(data.amount);
      finance["spent"]["amount"] =
        Number(finance["spent"]["amount"]) + Number(data.amount);
    } else {
      finance["balance"]["amount"] =
        Number(finance["balance"]["amount"]) + 2 * Number(data.amount);
      finance["received"]["amount"] =
        Number(finance["received"]["amount"]) + Number(data.amount);
      finance["spent"]["amount"] =
        Number(finance["spent"]["amount"]) - Number(data.amount);
    }

    if (sharedPeople) {
      Object.keys(sharedPeople).forEach((mobNo) => {
        let userDetails_financePath = `userDetails/${mobNo}/projects/${project.id}/finances`;
        userDetailsUpdate(userDetails_financePath, finance);
      });
    } else {
      let userDetails_financePath = `userDetails/${userId}/projects/${project.id}/finances`;
      userDetailsUpdate(userDetails_financePath, finance);
    }

    let financepath = `${project.id}/SE-pettycash/finances`;
    transactionUpdate(financepath, finance);
    let entryData = {
      [data.stamp]: {
        amount: Number(data.amount),
        balance: 0,
        date: data.date,
        isEditing: false,
        reason: data?.reason ? data?.reason : "None",
        tag: data.tag ? data.tag : "general",
        time: data.time,
        type: data.type === "debit" ? "credit" : "debit",
        userName: data.userName,
        uploads: data.uploads ? data.uploads : null,
      },
    };

    let transactionPath = `${project.id}/SE-pettycash/records/transactionHistory`;
    transactionUpdate(transactionPath, entryData);

    const lastUpdatedData = {
      date: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: new Date().getTime(),
    };

    let lastUpdatedPath = `${project.id}/SE-pettycash/lastUpdated`;
    transactionUpdate(lastUpdatedPath, lastUpdatedData);

    const lastTimeStamp = new Date().getTime();
    let userDetails_lastUpdatePath = `userDetails/${userId}/projects/${project.id}/`;
    userDetailsUpdate(userDetails_lastUpdatePath, {
      lastUpdated: lastTimeStamp,
    });

    setEntryMenuClicked({
      status: false,
      id: null,
    });
  };

  return (
    <div className={classes.container_wrapper}>
      {spinnerShow ? (
        <div className={classes.spinner}>
          <Oval
            visible={spinnerShow}
            height="80"
            width="80"
            color=" #fdd34d"
            secondaryColor="lightgray"
            ariaLabel="oval-loading"
          />
        </div>
      ) : (
        <>
          <div>
            {Object.keys(entriesData).length > 0 &&
              Object.keys(entriesData)
                .reverse()
                .map((data, index) => {
                  return entriesData[data]["reason"]
                    ?.toLowerCase()
                    .includes(searchEntries.toLowerCase()) ? (
                    <div
                      key={index}
                      className={classes.card}
                      style={{ position: "relative" }}
                      onClick={() => {
                        if (entryMenuClicked.status) {
                          setEntryMenuClicked({
                            status: false,
                            id: null,
                          });
                        }
                      }}>
                      <div className={classes.flex_wrapper}>
                        <p
                          className={classes.reason_amount_font}
                          style={{ flex: 0.6 }}>
                          {entriesData[data]["reason"]}
                        </p>
                        <div
                          className={
                            entriesData[data]["uploads"]
                              ? classes.attachmentIcon_amount_class
                              : ""
                          }>
                          {entriesData[data]["uploads"] && (
                            <GrAttachment
                              onClick={(event) => {
                                event.stopPropagation();
                                setSelectedAttachment({
                                  isSelected: true,
                                  details: entriesData[data],
                                });
                              }}
                            />
                          )}
                          <div
                            style={{ display: "flex", flexDirection: "row" }}>
                            <p
                              className={classes.reason_amount_font}
                              style={{
                                color:
                                  entriesData[data]["type"] === "debit"
                                    ? "#c90000"
                                    : "#117539",
                                marginRight: "20px",
                              }}>
                              {entriesData[data]["amount"]}
                            </p>
                            {role !== "Viewer" && (
                              <PiDotsThreeVerticalBold
                                style={{ marginLeft: "10px" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!entryMenuClicked.status) {
                                    setEntryMenuClicked({
                                      status: true,
                                      id: data,
                                    });
                                  } else {
                                    setEntryMenuClicked({
                                      status: false,
                                      id: null,
                                    });
                                  }
                                }}
                              />
                            )}

                            {entryMenuClicked.status &&
                              entryMenuClicked.id === data && (
                                <div className={classes.menu_options_wrapper}>
                                  <p
                                    className={classes.menu_options}
                                    onClick={() => {
                                      entrySelectionHandler({
                                        ...entriesData[data],
                                        stamp: data,
                                      });
                                      setEntryMenuClicked({
                                        status: false,
                                        id: null,
                                      });
                                    }}>
                                    Edit
                                  </p>
                                  <p
                                    className={classes.menu_options}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      entryDeleteHandler({
                                        ...entriesData[data],
                                        stamp: data,
                                      });
                                    }}>
                                    Delete
                                  </p>
                                  <p
                                    className={classes.menu_options}
                                    onClick={(event) => {
                                      event.stopPropagation();

                                      transactionTypeChangeHandler({
                                        ...entriesData[data],
                                        stamp: data,
                                      });
                                    }}>
                                    Change to{" "}
                                    {entriesData[data]["type"] === "debit"
                                      ? "+ve"
                                      : "-ve"}
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.flex_wrapper}>
                        <p className={classes.date_class}>
                          {entriesData[data]["date"]} <span>|</span>
                          {""} {entriesData[data]["time"]}
                        </p>
                        <div className={classes.tag}>
                          <span>
                            {entriesData[data]["userName"] === userName
                              ? "By you"
                              : entriesData[data]["userName"]}{" "}
                            {""}
                          </span>
                          <span>#{entriesData[data]["tag"]}</span>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
          </div>
          <>
            {selectedAttachment.isSelected && (
              <EntryAttachments
                selectedAttachment={selectedAttachment}
                setSelectedAttachment={setSelectedAttachment}
              />
            )}
          </>
          <div>
            <div
              className={classes.no_data}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                color: "#333",
              }}>
              <p>End of records</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
