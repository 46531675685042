import React, { useState } from "react";
import classes from "./RequestCard.module.css";
import { FaChevronCircleDown } from "react-icons/fa";

export default function RequestCard(props) {
  const {
    cardData,
    statusChangeHandler,
    role,
    requestUpdateHandler,
    userNumber,
  } = props;

  const [mouseEntered, setMouseEntered] = useState(false);

  let cardDetails = cardData[Object.keys(cardData)[0]];

  const reqHistory = cardDetails.requestHistory;

  const lastReqHistoryStamp =
    Object.keys(reqHistory)[Object.keys(reqHistory).length - 1];

  const firstReqHistoryStamp = Object.keys(reqHistory)[0];

  let requestedTo = "";
  let associatedPeople = [];

  Object.keys(cardDetails.associatedPeople).forEach((stamp, index) => {
    associatedPeople.push(cardDetails.associatedPeople[stamp]["number"]);
    if (index < Object.keys(cardDetails.associatedPeople).length - 1) {
      requestedTo =
        requestedTo + `${cardDetails.associatedPeople[stamp]["name"]},`;
    } else {
      requestedTo =
        requestedTo + `${cardDetails.associatedPeople[stamp]["name"]}`;
    }
  });

  return (
    <div className={classes.card}>
      <div className={classes.amount_reason_class}>
        <p
          style={{
            fontWeight: "500",
            color: cardDetails.type === "debit" ? "red" : "#117539",
          }}
        >
          {cardDetails["amount"]}
        </p>
        <div style={{ flex: 0.7 }}>
          <p style={{ textAlign: "end", fontWeight: "500" }}>
            {cardDetails.reason}
          </p>
          <p style={{ textAlign: "end", fontSize: "12px" }}>
            On: {cardDetails["requestHistory"][lastReqHistoryStamp]["date"]} |{" "}
            {cardDetails["requestHistory"][lastReqHistoryStamp]["time"]}
          </p>
        </div>
      </div>
      <div className={classes.info_statusBtn_class}>
        <div>
          <p className={classes.infoOption}>
            {cardDetails["requestHistory"][lastReqHistoryStamp]["status"]} by:{" "}
            <span>
              {cardDetails["requestHistory"][lastReqHistoryStamp]["By"]}
            </span>
          </p>
          <p className={classes.infoOption}>
            Requested to: <span>{requestedTo}</span>
          </p>
          <p className={classes.infoOption}>
            Due Date : <span>{cardDetails.date}</span>
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {role === "Owner" &&
            cardDetails["requestHistory"][lastReqHistoryStamp]["status"] ===
              "Approved" && (
              <div
                className={classes.statusText}
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={() => requestUpdateHandler(cardData)}
              >
                <p>Update</p>
              </div>
            )}
          <div className={classes.status_arrowIcon_class}>
            <div
              className={classes.statusText}
              style={{
                border:
                  cardDetails["requestHistory"][lastReqHistoryStamp][
                    "status"
                  ] === "Approved"
                    ? "1px solid #117539"
                    : cardDetails["requestHistory"][lastReqHistoryStamp][
                        "status"
                      ] === "Requested"
                    ? "1px solid #FDD34D"
                    : cardDetails["requestHistory"][lastReqHistoryStamp][
                        "status"
                      ] === "Declined"
                    ? "1px solid red"
                    : "1px solid grey",
                color:
                  cardDetails["requestHistory"][lastReqHistoryStamp][
                    "status"
                  ] === "Approved"
                    ? "#117539"
                    : cardDetails["requestHistory"][lastReqHistoryStamp][
                        "status"
                      ] === "Requested"
                    ? "#FDD34D"
                    : cardDetails["requestHistory"][lastReqHistoryStamp][
                        "status"
                      ] === "Declined"
                    ? "red"
                    : "grey",
              }}
            >
              <p>
                {cardDetails["requestHistory"][lastReqHistoryStamp]["status"]}
              </p>
            </div>

            {role !== "Viewer" && associatedPeople.includes(userNumber) && (
              <div
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                style={{ position: "relative", padding: "5px" }}
              >
                <FaChevronCircleDown
                  size={20}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                {mouseEntered && (
                  <div className={classes.onHoverDropdown_wrapper}>
                    {["Approve", "Decline", "On hold"].map((option) => {
                      return (
                        <div
                          key={option}
                          className={classes.onHover_option}
                          onClick={() => {
                            statusChangeHandler(cardData, option);
                            setMouseEntered(false);
                          }}
                          style={{
                            border:
                              option === "Approve"
                                ? "1px solid #117539"
                                : option === "Decline"
                                ? "1px solid red"
                                : "1px solid grey",
                            color:
                              option === "Approve"
                                ? "#117539"
                                : option === "Decline"
                                ? "red"
                                : "grey",

                            margin: "8px 0px",
                            borderRadius: "4px",
                          }}
                        >
                          <p>{option}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
