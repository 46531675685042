import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
//
// let environment = "staging";
let environment = "production";
// export let environment = "production";

// Import the functions you need from the SDKs you need
let firebaseConfig = {};

if (environment === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyC7lCprD7hcUy38jO_vyJRNpNE3Py0a8J4",
    authDomain: "staart-works-staging.firebaseapp.com",
    databaseURL: "https://staart-works-staging-default-rtdb.firebaseio.com",
    projectId: "staart-works-staging",
    storageBucket: "staart-works-staging.appspot.com",
    messagingSenderId: "412834561343",
    appId: "1:412834561343:web:86f88e744d5658b0a1a78a",
    measurementId: "G-N23VFF33CC",
  };
} else if (environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyBKp1SaYNMM6QDpkvlGq8XY2It2z0rUn9Y",
    authDomain: "staart-works-77f18.firebaseapp.com",
    databaseURL: "https://staart-works-77f18-default-rtdb.firebaseio.com",
    projectId: "staart-works-77f18",
    storageBucket: "staart-works-77f18.appspot.com",
    messagingSenderId: "445732820061",
    appId: "1:445732820061:web:9de2637965f8ba0bff29b4",
    measurementId: "G-W50N9VG1J2",
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);
