import { useState } from "react";
import { db } from "../Firebase/config";
import { onValue, ref, update, get, remove } from "firebase/database";

import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

export const fetchProjects = (path, cb) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      (snapshot) => {
        if (snapshot.exists()) {
          cb(snapshot.val());
        } else {
          console.log("No projects found");
          cb({});
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const fetchUserName = (path, cb) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      (snapShot) => {
        if (snapShot.exists()) {
          let data = snapShot.val();
          cb(data);
        } else {
          cb({ UserName: "Admin", accountType: true });
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const fetchConversations = async (path, setChats) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      (snapshot) => {
        const values = snapshot.val();
        if (values == null) {
          setChats([]);
        } else {
          setChats(bubbleSort(Object.values(values)));
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};
export const fetchMessages = async (
  path,
  setChatData,
  active,
  inactiveTimeStamp
) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      (snapshot) => {
        const values = snapshot.val();
        // setCompleteOnlineChat(values);
        if (values != null) {
          const chatData = Object.values(values);
          if (active == false) {
            console.log("reached if from fetch message");
            let i = 0;
            while (
              i < chatData.length &&
              Object.keys(values)[i] < inactiveTimeStamp
            ) {
              i++;
            }
            setChatData(chatData.slice(0, i));
          } else {
            setChatData(chatData);
            console.log("reached else from fetch message");
            console.log("chatData", chatData);
          }
        } else {
          setChatData([]);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};
function bubbleSort(arr) {
  const length = arr.length;

  for (let i = 0; i < length; i++) {
    let swapped = false;

    for (let j = 0; j < length - i - 1; j++) {
      if (
        arr[j].lastInformation.lastTextTimeStamp <
        arr[j + 1].lastInformation.lastTextTimeStamp
      ) {
        const temp = arr[j];
        arr[j] = arr[j + 1];
        arr[j + 1] = temp;
        swapped = true;
      }
    }

    if (!swapped) break;
  }
  return arr;
}
export const fetchEntries = (path, cb) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      (snapShot) => {
        if (snapShot.exists()) {
          cb(snapShot.val());
        } else {
          console.log("No entries were found");
          cb({});
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};
export const firstEntryCreation = (path, data) => {
  update(ref(db, path), data);
};

export const userDetailsUpdate = (path, data) => {
  update(ref(db, path), data);
};

export const conversation_UserDetilsUpdate = (path, data) => {
  update(ref(db, path), data);
};
export const transactionUpdate = (path, data) => {
  update(ref(db, path), data);
};

export const deleteHandler = (path) => {
  remove(ref(db, path)).then(() => {
    console.log("succesfully deleted");
  });
};



export const fileUploadHandler = (file, cb) => {
  const fileName = String(new Date().getTime());

  const storage = getStorage();

  let path = `SE-Pettycash/${fileName}.jpg`;
  const imageRef = storageRef(storage, path);
  uploadBytes(imageRef, file)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref)
        .then((url) => {
          // saveData(url);
          console.log("url of uploaded images :", url);
          cb({
            [fileName]: {
              name: `${fileName}.jpg`,
              type: file.type,
              uri: url,
            },
            status: "success",
          });
        })
        .catch((error) => {
          console.log("error1 while uploading the image :", error);
          cb({ status: "failed" });
        });
    })
    .catch((error) => {
      console.log("error2 while uploading the image :", error);
      cb({ status: "failed" });
    });
};
