import { useState } from "react";
import Modal from "react-modal";
import classes from "./createProject.module.css";
import {
  conversation_UserDetilsUpdate,
  firstEntryCreation,
  userDetailsUpdate,
} from "../api/api";

const suggestion = ["Daily Payments", "Investment", "Emergency"];

export default function Createproject(props) {
  const {
    projectCreationModalOpen,
    setProjectCreationModalOpen,
    userId,
    userName,
    setSelectedProject,
  } = props;

  const [enteredName, setEnteredName] = useState();

  const createHandler = () => {
    let stamp = new Date().getTime();

    let date = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    let hr = new Date().getHours();
    let minutes = new Date().getMinutes();
    let sec = new Date().getSeconds();

    let ddmmyyyy = `${date}-${month}-${year}`;
    console.log("full date :", ddmmyyyy);

    let time = `${hr}:${minutes}:${sec}`;
    console.log("time :", time);

    let financeData = {
      balance: {
        amount: 0,
        attribute: {
          name: "Balance",
        },
      },
      received: {
        amount: 0,
        attribute: {
          name: "Received",
        },
      },
      spent: {
        amount: 0,
        attribute: {
          name: "Spent",
        },
      },
    };

    const userDetailsData = {
      [stamp]: {
        finances: { ...financeData },
        id: stamp,
        name: enteredName.trim(),
        role: "Owner",
        privatelyShare: false,
        lastUpdated: stamp,
      },
    };

    const conversationData = {
      [stamp]: {
        lastInformation: {
          lastSeen: {
            lastMessageSeen: false,
            totalUnseenMessage: 0,
          },
          lastText: "No message yet",
          lastTextTimeStamp: stamp,
        },
        memberIds: userId,
        membersName: userName,
        name: enteredName.trim(),
        personal: false,
        projectId: stamp,
      },
    };

    const firstEntryData = {
      finances: { ...financeData },
      lastUpdated: {
        date: ddmmyyyy,
        time: time,
        timeStamp: new Date().getTime(),
      },
      offline: true,
      records: {
        balance: {
          amount: 0,
        },
        name: enteredName.trim(),
        received: {
          amount: 0,
        },
        spent: {
          amount: 0,
        },
        transactionHistory: {
          [new Date().getTime()]: {
            amount: 0,
            balance: 0,
            date: ddmmyyyy,
            isEditing: false,
            reason: "None",
            tag: "general",
            time: time,
            type: "debit",
            userName: userName,
          },
        },
      },
      request: {
        approved: {
          amount: 0,
        },
        declined: {
          amount: 0,
        },
        onHold: {
          amount: 0,
        },
        requested: {
          amount: 0,
        },
      },
    };

    let userDetailsPath = `userDetails/${userId}/projects`;

    let conversationPath = `conversations/${userId}`;

    let firstEntryPath = `${stamp}/SE-pettycash`;

    userDetailsUpdate(userDetailsPath, userDetailsData);
    conversation_UserDetilsUpdate(conversationPath, conversationData);
    firstEntryCreation(firstEntryPath, firstEntryData);
    setEnteredName("");
    setProjectCreationModalOpen(false);
    setSelectedProject(userDetailsData[stamp]);
  };

  return (
    <Modal
      isOpen={projectCreationModalOpen}
      onRequestClose={() => setProjectCreationModalOpen(false)}
      contentLabel="Create Project Modal"
      className={classes.content}
      overlayClassName={classes.overlay}>
      <div className={classes.modalWrapper}>
        <p
          className={classes.closeBtn}
          onClick={() => setProjectCreationModalOpen(false)}>
          Close
        </p>
        <p className={classes.heading}>Add Project</p>

        <input
          type="text"
          className={classes.inputField}
          placeholder="Enter project name"
          value={enteredName}
          onChange={(e) => setEnteredName(e.target.value)}
        />

        <p className={classes.note}>Select one or type your own</p>

        {suggestion.map((name, index) => (
          <div
            key={index}
            className={classes.suggestion}
            onClick={() => setEnteredName(name)}>
            <p>{name}</p>
          </div>
        ))}

        <div
          className={classes.createBtn}
          onClick={() => enteredName && createHandler()}>
          <p>Create</p>
        </div>
      </div>
    </Modal>
  );
}
