import "./App.css";
import Home from "./pages/Home";
import LogIn from "./pages/Login";
import PrivateRoute from "./pages/PrivateRoute";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  const route = createBrowserRouter([
    { path: "/", element: <LogIn /> },
    { path: "/home", element: <PrivateRoute><Home /></PrivateRoute> },
  ]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
