import React, { useEffect, useState } from "react";
import classes from "./Login.module.css";
import logo from "../assets/imgs/Staart-Logo.png";
import { useNavigate } from "react-router-dom";

export default function LogIn() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [enteredOTP, setEnteredOTP] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    console.log("userId", userId);
    if (userId) {
      const isAuthenticated = true;
      navigate("/home", { state: { isAuthenticated, mobileNumber: userId } });
    }

    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };

    document.addEventListener("keydown", handleEnterKey);

    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  }, [otpSent, mobileNumber, enteredOTP]);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const sendOTP = () => {
    if (mobileNumber.length < 10) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    fetch(
      `https://2factor.in/API/V1/49492693-a3e8-11ed-813b-0200cd936042/SMS/+91${mobileNumber}/AUTOGEN/OTP`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          setOtpSent(true);
          setResendTimer(30);
        } else {
          console.error("Failed to send OTP");
        }
      })
      .catch((error) => {
        console.error("Error sending OTP:", error);
      });
  };

  const verifyOTP = () => {
    if (enteredOTP.length < 6) {
      alert("Please enter the 6-digit OTP.");
      return;
    }

    fetch(
      `https://2factor.in/API/V1/49492693-a3e8-11ed-813b-0200cd936042/SMS/VERIFY3/${mobileNumber}/${enteredOTP}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          const isAuthenticated = true;
          navigate("/home", { state: { isAuthenticated, mobileNumber } });
          localStorage.setItem("userId", mobileNumber);
        } else {
          console.error("OTP verification failed");
          alert("Invalid OTP");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
      });
  };

  const handleLogin = () => {
    if (otpSent) {
      verifyOTP();
    } else {
      sendOTP();
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.login_container}>
        <div className={classes.welcome_heading}>
          <div>
            <p className={classes.welcome_text}>welcome to</p>
            <p className={classes.compName}>Staart works</p>
          </div>
          <div>
            <img
              src={logo}
              alt="staart logo"
              width={"100px"}
              height={"100px"}
            />
          </div>
        </div>
        <p className={classes.login_text}>
          {otpSent ? "Enter OTP" : "Login"}{" "}
          <span style={{ fontSize: "14px", fontWeight: "normal" }}>or</span>{" "}
          Signup
        </p>

        {!otpSent && (
          <input
            type="text"
            value={mobileNumber}
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d*$/.test(value)) {
                setMobileNumber(value);
              }
            }}
            maxLength={10}
            className={classes.inputField}
            placeholder="Enter phone number"
            inputMode="numeric"
            pattern="\d*"
          />
        )}

        {otpSent && (
          <input
            type="text"
            value={enteredOTP}
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d*$/.test(value)) {
                setEnteredOTP(value);
              }
            }}
            maxLength={6}
            className={classes.inputField}
            placeholder="Enter OTP"
            inputMode="numeric"
            pattern="\d*"
          />
        )}

        <div className={classes.continueBtn} onClick={handleLogin}>
          {otpSent ? "Verify OTP" : "Send OTP"}
        </div>

        {otpSent && (
          <p
            style={{
              marginTop: "10px",
            }}>
            <span
              className={resendTimer === 0 ? classes.pointer : classes.disabled}
              onClick={resendTimer === 0 ? sendOTP : null}>
              Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
            </span>
          </p>
        )}
      </div>
    </div>
  );
}
