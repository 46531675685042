import React, { useState } from "react";
import ChatList from "./leftComponent";
export default function Chat(props) {
  const {
    selectedChat,
    setSelectedChat,
    chats,
    userId,
    chatData,
    setChatData,
  } = props;
  return (
    <div className="ChatScreen">
      <div>
        <ChatList
          chats={chats}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          userId={userId}
          chatData={chatData}
          setChatData={setChatData}
        />
      </div>
    </div>
  );
}