import React, { useEffect, useState } from "react";
import "./leftComponent.css";
import { fetchMessages } from "../../api/api";
import updateValues from "../../Firebase/firebase";

const ChatList = (props) => {
  const {
    selectedChat,
    setSelectedChat,
    chats,
    userId,
    chatData,
    setChatData,
  } = props;
  const [search, setSearch] = useState("");
  const [filteredChats, setFilteredChats] = useState([]);
  const [completeOnlineChat, setCompleteOnlineChat] = useState();
  const [selectedChatId, setSelectedChatId] = useState(null);

  useEffect(() => {
    const filtered = Object.values(chats).filter((chat) =>
      chat?.name?.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredChats(filtered);
  }, [search, chats]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightSearchTerm = (name, search) => {
    if (!search) return name;

    const escapedSearch = escapeRegExp(search);
    const parts = name.split(new RegExp(`(${escapedSearch})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="chat-list">
      <div className="chat-list-header">
        <div className="chat-list-title">Chats</div>
        <div className="chat-list-search">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="chat-list-body">
        {filteredChats.map((chat) => (
          <div
            className="chat-list-item"
            key={chat.id}
            style={{
              backgroundColor:
                selectedChatId === chat.name ? "#ebebf0" : "transparent",
            }}
            onClick={() => {
              setSelectedChatId(chat.name);
              setSelectedChat(chat);
              let convId = chat?.personal
                ? chat?.conversationsId
                : chat?.projectId
                ? chat?.projectId
                : chat?.conversationsId;

              let personal = chat?.personal;
              let active = chat?.lastInformation?.active?.active;
              let inactiveTimeStamp =
                chat?.lastInformation?.active?.inactiveTimeStamp;
              let path = personal
                ? "privatelyShared/privateConversations/"
                : "projectGroupChats/";
              if (convId === "customerRelation") {
                path = "tokens/" + userId + "/messages/";
              } else {
                path = personal
                  ? "privatelyShared/privateConversations/" + convId + "/"
                  : "projectGroupChats/" + convId + "/";
              }
              console.log("path", path);
              fetchMessages(path, setChatData, active, inactiveTimeStamp);
              let lastInfoObject = {
                lastMessageSeen: false,
                totalUnseenMessages: 0,
              };
              if (chat?.name === "StaartWorks Team") {
                if (chat.lastInformation.lastSeen.totalUnseenMessages !== 0) {
                  updateValues(
                    "POST",
                    "conversations/" +
                      userId +
                      "/customerRelation/lastInformation/lastSeen/",
                    lastInfoObject
                  );
                }
              } else {
                if (chat.lastInformation.lastSeen.totalUnseenMessages !== 0) {
                  if (chat.personal) {
                    updateValues(
                      "POST",
                      "conversations/" +
                        userId +
                        "/" +
                        chat.conversationsId +
                        "/lastInformation/lastSeen/",
                      lastInfoObject
                    );
                  } else {
                    updateValues(
                      "POST",
                      "conversations/" +
                        userId +
                        "/" +
                        chat.projectId +
                        "/lastInformation/lastSeen/",
                      lastInfoObject
                    );
                  }
                }
              }
            }}>
            <div
              className="chat-list-item-avatar-firstLetter"
              style={{
                backgroundColor: "#fdd34d",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                alignItems: "center",
                height: "50px",
                minHeight: "50px",
                maxHeight: "50px",
                width: "50px",
                minWidth: "50px",
                maxWidth: "50px",
                fontWeight: "400",
                color: "black",
                fontSize: "20px",
                textTransform: "uppercase",
                fontFamily: "Montserrat",
              }}>
              {chat?.name ? chat.name[0] : "U"}
            </div>
            <div className="chat-list-item-content">
              <p className="chat-list-item-name">
                {highlightSearchTerm(chat.name, search)}
              </p>
              <p className="chat-list-item-text">
                {chat.lastInformation?.lastText?.length > 15
                  ? `${chat.lastInformation?.lastText?.substring(0, 15)}...`
                  : chat.lastInformation?.lastText}
              </p>
            </div>
            <div className="last-time">
              {new Date(chat.lastInformation.lastTextTimeStamp).getDate() +
                " / " +
                (new Date(chat.lastInformation.lastTextTimeStamp).getMonth() +
                  1) +
                " / " +
                new Date(chat.lastInformation.lastTextTimeStamp).getFullYear()}
            </div>
            {chat?.lastInformation?.lastSeen?.lastMessageSeen && (
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                  alignSelf: "flex-end",
                  backgroundColor: "#FDD34D",
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <p
                  style={{
                    fontFamily: "Montserrat-SemiBold",
                    fontSize: 12,
                  }}>
                  {chat.lastInformation.lastSeen.totalUnseenMessages === 0
                    ? null
                    : chat.lastInformation.lastSeen.totalUnseenMessages}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatList;
