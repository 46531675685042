export function filterEntriesAndCalculateBalance(entriesData) {
    const tagBalances = {};
    const tagCounts = {};
    const entriesArray = Object.values(entriesData);
    const totalEntries = entriesArray.length;
  
    entriesArray.forEach((entry) => {
      const { tag, type, amount } = entry;
  
      if (!tagBalances[tag]) {
        tagBalances[tag] = 0;
        tagCounts[tag] = 0;
      }
  
      tagCounts[tag] += 1;
  
      if (type.toLowerCase() === "credit") {
        tagBalances[tag] += amount;
      } else if (type.toLowerCase() === "debit") {
        tagBalances[tag] -= amount;
      }
    });
  
    const tagBalanceArray = Object.entries(tagBalances).map(([tag, balance]) => ({
      tag,
      balance,
      percentage: ((tagCounts[tag] / totalEntries) * 100).toFixed(2), // Calculate percentage based on transaction counts
    }));
  
    tagBalanceArray.sort((a, b) => b.balance - a.balance);
  
    const topTags = tagBalanceArray.slice(0, Math.min(3, tagBalanceArray.length));
  
    return topTags;
  } 