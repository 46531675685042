import React from "react";
import Chat from "./rightComponent";
export default function ChatScreen(props) {
  const {
    selectedChat,
    setSelectedChat,
    chats,
    userId,
    chatData,
    setChatData,
  } = props;
  return (
    <div>
      <Chat
        chats={chats}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        userId={userId}
        chatData={chatData}
        setChatData={setChatData}
      />
    </div>
  );
}