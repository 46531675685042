import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import classes from "./BalanceCard.module.css";
import { IoMdCalendar } from "react-icons/io";
import { BiDownload } from "react-icons/bi";
import { DatePicker } from "antd";
import { fetchEntries } from "../../api/api";
import logo from "../../assets/imgs/Staart-Logo.png";
const { RangePicker } = DatePicker;

export default function BalanceCard(props) {
  const {
    setSelectedDateRange,
    finance,
    project,
    allEntries,
    setAllEntries,
    projectId,
    userId,
    displayReceived,
    setDisplayReceived,
    displaySpent,
    setDisplaySpent,
    selectedDataRange,
    entriesData,
    setEntriesData,
  } = props;

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [ogEntries, setOgEntries] = useState({});
  let entriesForReport = Object.values(entriesData).reverse();
  useEffect(() => {
    if (projectId && userId) {
      const retrieveEntries = (data) => {
        setOgEntries(data);
      };
      const getEntries = async () => {
        try {
          let path = `${projectId}/SE-pettycash/records/transactionHistory`;
          await fetchEntries(path, retrieveEntries);
        } catch (error) {
          console.log("error on entries page :", error);
        }
      };

      getEntries();
    }
  }, [projectId, userId]);

  useEffect(() => {
    setDisplayReceived(false);
    setDisplaySpent(false);
  }, [ogEntries]);

  let entries = Object.values(entriesData);
  const spentEntries = entries.filter((entry) => entry.type === "debit");
  const receivedEntries = entries.filter((entry) => entry.type === "credit");
  const totalSpent = spentEntries.reduce((acc, entry) => acc + entry.amount, 0);
  const totalReceived = receivedEntries.reduce(
    (acc, entry) => acc + entry.amount,
    0
  );
  const totalBalance = totalReceived - totalSpent;

  const spentFilter = () => {
    setDisplaySpent(!displaySpent);
    setDisplayReceived(false);
    setAllEntries(displaySpent ? ogEntries : spentEntries);
  };

  const receivedFilter = () => {
    setDisplayReceived(!displayReceived);
    setDisplaySpent(false);
    setAllEntries(displayReceived ? ogEntries : receivedEntries);
  };

  const balanceFilter = () => {
    setDisplayReceived(false);
    setDisplaySpent(false);
    setAllEntries(ogEntries);
  };

  useEffect(() => {
    if (calendarOpen) {
      setCalendarOpen(false);
    }
  }, [project]);

  const generatePDF = () => {
    const doc = new jsPDF();
    const displayedSpent = totalSpent;
    const displayedReceived = totalReceived;
    const displayedBalance = totalBalance;
    const currentDate = new Date();
    const date = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const hour = currentDate.getHours().toString().padStart(2, "0");
    const min = currentDate.getMinutes().toString().padStart(2, "0");

    const addHeader = () => {
      // Watermark (behind everything)
      doc.setGState(new doc.GState({ alpha: 0.1 }));
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(50);
      doc.setTextColor("#F3F7EC");
      doc.text("CONFIDENTIAL", 125, 150, { align: "center", angle: 45 });
      doc.setGState(new doc.GState({ alpha: 1 }));

      // Header with background color
      doc.setFillColor("#002FB6");
      doc.rect(0, 0, 210, 40, "F");

      // Header Text
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(28);
      doc.setTextColor("#FFFFFF");
      doc.text("Cash Flow Statement", 105, 25, { align: "center" });

      // Project Name and Logo
      doc.setFont("Helvetica", "Regular");
      doc.setFontSize(16);
      doc.setTextColor("#333");
      doc.text(`Project: ${project.name}`, 20, 50);
      doc.addImage(logo, "PNG", 160, 10, 23, 20);

      // Account Summary
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(20);
      doc.setTextColor("#002FB6");
      doc.text("Account Summary", 105, 70, { align: "center" });

      doc.setFont("Helvetica", "Regular");
      doc.setFontSize(16);
      doc.setTextColor("#333");
      const formatDate = (dateString) => {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        return `${day}-${month}-${year}`;
      };

      const startDateFormatted = selectedDataRange?.startDate
        ? formatDate(selectedDataRange.startDate)
        : "All Time";
      const endDateFormatted = selectedDataRange?.endDate
        ? formatDate(selectedDataRange.endDate)
        : "Present";

      doc.text(
        `Date Range: ${startDateFormatted} to ${endDateFormatted}`,
        20,
        85
      );

      // Summary Details with boxes
      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(14);
      doc.setTextColor("#333");

      doc.text("Summary", 20, 100);

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(20, 102, 190, 102);

      // Spent
      doc.setTextColor("#C90000"); // Red color for Spent
      doc.text("Spent:", 20, 115);
      doc.text(`${displayedSpent.toFixed(2)} INR`, 60, 115);

      // Received
      doc.setTextColor("#117539"); // Green color for Received
      doc.text("Received:", 20, 125);
      doc.text(`${displayedReceived.toFixed(2)} INR`, 60, 125);

      // Balance
      doc.setTextColor(displayedBalance >= 0 ? "#117539" : "#C90000"); // Green if positive, Red if negative
      doc.text("Balance:", 20, 135);
      doc.text(`${displayedBalance.toFixed(2)} INR`, 60, 135);

      doc.setLineWidth(0.1);
      doc.line(20, 140, 190, 140);
    };

    const addFooter = (y) => {
      // Footer
      doc.setFont("Helvetica", "Regular");
      doc.setFontSize(10);
      doc.setTextColor("#333");
      doc.text(
        `Generated on: ${date}/${month}/${year} at ${hour}:${min}`,
        105,
        y,
        { align: "center" }
      );
      doc.text("© StaartWorks - All Rights Reserved", 105, y + 10, {
        align: "center",
      });
    };

    const addTableHeader = (y) => {
      // Transaction Details Table
      doc.setDrawColor("#002FB6");
      doc.setFillColor("#E6E6FA");
      doc.roundedRect(15, 140, 180, 20, 3, 3, "FD"); // Increased height for margin

      doc.setFont("Helvetica", "Bold");
      doc.setFontSize(12);
      doc.setTextColor("#002FB6");
      doc.text("Date", 25, 152);
      doc.text("Reason", 65, 152);
      doc.text("Amount", 125, 152);
      doc.text("Type", 165, 152);
    };

    addHeader();
    addTableHeader(150);

    let y = 170; // Increased starting y-coordinate for margin
    entriesForReport.forEach((entry, index) => {
      if (y > 275) {
        // If y exceeds a certain limit, add a new page
        doc.addPage();
        y = 40;
      }

      const reasonText = doc.splitTextToSize(entry.reason, 60); // Split reason text to fit within 60 units width
      const rowHeight = 10;
      const cellHeight = rowHeight * reasonText.length;

      // Draw cell background
      doc.setFillColor(index % 2 === 0 ? "#F0F0F0" : "#FFFFFF");
      doc.rect(15, y - 5, 180, cellHeight, "F");

      // Draw date only on the first line
      doc.setFont("Helvetica", "Regular");
      doc.setFontSize(10);
      doc.setTextColor("#333");
      doc.text(entry.date.split(".")[0], 25, y);

      reasonText.forEach((line, i) => {
        doc.text(line, 65, y + i * rowHeight);
      });

      // Draw amount and type only on the first line
      if (entry.type === "debit") {
        doc.setTextColor("#C90000");
      } else {
        doc.setTextColor("#117539");
      }
      doc.text(`${entry.amount.toFixed(2)}`, 125, y);

      doc.setTextColor("#333");
      doc.text(entry.type === "debit" ? "Debit" : "Credit", 165, y);

      y += cellHeight;
    });

    addFooter(y + 20);

    doc.save(`CashFlowStatement_${project.name}_${year}${month}${date}.pdf`);
  };

  return (
    <div className={classes.balanceCard}>
      <div className={classes.dates_reports_wrapper}>
        <div
          className={classes.icon_text_aligner}
          onClick={() => {
            setCalendarOpen(!calendarOpen);
            setSelectedDateRange({ startDate: null, endDate: null });
          }}>
          <IoMdCalendar size={20} style={{ marginRight: "10px" }} />
          <span>Select dates</span>
        </div>
        <div className={classes.icon_text_aligner} onClick={generatePDF}>
          <span style={{ color: "#3A58AE" }}>Download Report</span>
          <BiDownload
            size={20}
            color="#3A58AE"
            style={{ marginLeft: "10px" }}
          />
        </div>
      </div>

      {calendarOpen && (
        <RangePicker
          onChange={(dates, dateStrings) => {
            if (dates) {
              let [start, end] = dateStrings;
              setSelectedDateRange({
                startDate: start.replaceAll("-", ""),
                endDate: end.replaceAll("-", ""),
              });
              console.log(dateStrings);
              console.log(selectedDataRange);
            } else {
              setSelectedDateRange({
                startDate: null,
                endDate: null,
              });
            }
          }}
        />
      )}

      <div
        className={classes.transaction_balance}
        style={{ bottom: calendarOpen ? "3px" : "5px" }}>
        <div className={classes.balaceOption} onClick={spentFilter}>
          <p
            className={classes.optionHeading}
            style={{ marginBottom: calendarOpen ? "0px" : "5px" }}>
            Spent
          </p>
          <p
            className={classes.optionMoney}
            style={{
              backgroundColor: displaySpent ? "#c90000" : "transparent",
              color: displaySpent ? "#fff" : "#c90000",
              borderRadius: "5px",
              padding: "0 5px",
            }}>
            {totalSpent}
          </p>
        </div>
        <div className={classes.balaceOption} onClick={receivedFilter}>
          <p
            className={classes.optionHeading}
            style={{ marginBottom: calendarOpen ? "0px" : "5px" }}>
            Received
          </p>
          <p
            className={classes.optionMoney}
            style={{
              backgroundColor: displayReceived ? "#117539" : "transparent",
              color: displayReceived ? "#fff" : "#117539",
              borderRadius: "5px",
              padding: "0 5px",
            }}>
            {totalReceived}
          </p>
        </div>
        <div className={classes.balaceOption} onClick={balanceFilter}>
          <p
            className={classes.optionHeading}
            style={{ marginBottom: calendarOpen ? "0px" : "5px" }}>
            Balance
          </p>
          <p className={classes.optionMoney}>{totalBalance}</p>
        </div>
      </div>
    </div>
  );
}
