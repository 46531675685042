import React, { useState, useRef, useEffect } from "react";
import "./rightComponent.css";
import { GrAttachment } from "react-icons/gr";
import { IoIosSend } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Modal from "./imageModal";
import updateValues from "../../Firebase/firebase";
import { numMessageupdate } from "../helperFolder/numMessage";
import {
  getStorage,
  uploadBytesResumable,
  ref as refFromStorage,
  getDownloadURL,
} from "firebase/storage";

const Chat = (props) => {
  const [alreadyAddedMembers, setAlreadyAddedMembers] = useState(null);
  const [namesOfMembers, setNamesOfMembers] = useState(null);
  const [userIdsOfMembers, setUserIdsOfMembers] = useState(null);
  const { selectedChat, setSelectedChat, userId, chatData, setChatData } =
    props;
  const [images, setImages] = useState({});
  const [numUpdate, setNumUpdate] = useState(false);
  const [messageCount, setMessageCount] = useState(
    selectedChat?.lastInformation?.lastSeen?.totalUnseenMessages
  );
  const [input, setInput] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [userName, setUserName] = useState("");
  const chatContainerRef = useRef(null);
  const [fileStatus, setFileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setInput("");
  }, [selectedChat]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await updateValues(
          "GET",
          `userDetails/${userId}/personalInfo/UserName`,
          null
        );
        setUserName(response.data);
      } catch (error) {
        console.error("Failed to fetch user name:", error);
      }
    };

    fetchUserName();
  }, [userId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatData]);

  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        console.log("You did not select any image");
        return;
      }

      setFileStatus(true);
      setIsLoading(true);

      const { type } = file;
      const storage = getStorage();
      const name = String(new Date().getTime());
      const storageRef = refFromStorage(
        storage,
        "SE-Pettycash/" + name + ".jpg"
      );

      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: type,
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress if needed
        },
        (error) => {
          console.error("Error uploading photo:", error);
          setFileStatus(false);
          setIsLoading(false);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          setFileStatus(null);
          setIsLoading(false);

          const tempFileDetails = {
            [name]: {
              type: type,
              uri: url,
              name: name + "." + type.split("/")[1],
            },
          };

          handleSend(tempFileDetails);
        }
      );
    } catch (err) {
      console.error("Error choosing file:", err);
      setFileStatus(false);
      setIsLoading(false);
    }
  };

  const handleSend = async (tempFileDetails = null) => {
    if (!tempFileDetails && !input.trim()) {
      alert("The input field is empty. Please type something.");
      return;
    }
    let timeStamp = new Date().getTime();
    let tempMessage = input;

    setInput("");

    const newMessage = {
      id: timeStamp,
      userId: userId,
      text: tempMessage,
      time: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      userName: userName,
      type: "text",
      images: tempFileDetails ? tempFileDetails : null,
    };

    const convId = selectedChat?.personal
      ? selectedChat?.conversationsId
      : selectedChat?.projectId;

    if (selectedChat?.personal && convId !== "customerRelation") {
      await updateValues(
        "POST",
        `privatelyShared/privateConversations/${convId}/${timeStamp}/`,
        newMessage
      );
    } else if (convId === "customerRelation") {
      await updateValues(
        "POST",
        `tokens/${userId}/messages/${timeStamp}/`,
        newMessage
      );
    } else {
      await updateValues(
        "POST",
        `projectGroupChats/${convId}/${timeStamp}/`,
        newMessage
      );
    }

    setImages({});
    setMessageCount((prevCount) =>
      prevCount !== undefined ? prevCount + 1 : 1
    );

    let lastInfoObject = {
      lastSeen: {
        lastMessageSeen: false,
        totalUnseenMessages: 0,
      },
      lastText: tempMessage,
      lastTextTimeStamp: timeStamp,
    };

    if (convId === "customerRelation") {
      updateValues("POST", `tokens/${userId}/lastUpdated`, timeStamp);
      updateValues("POST", `tokens/${userId}/lastMessageSeen`, false);
      updateValues(
        "POST",
        `conversations/${userId}/${convId}/lastInformation/`,
        lastInfoObject
      );
    } else {
      if (selectedChat?.personal && convId !== "customerRelation") {
        const displayText = selectedChat?.personal
          ? selectedChat?.number
          : selectedChat?.members;

        let numMessage = await updateValues(
          "GET",
          `conversations/${displayText}/${convId}/lastInformation/lastSeen/`,
          null
        );

        let countNow = numMessage.data
          ? numMessage.data.totalUnseenMessages + 1
          : 1;

        lastInfoObject.lastSeen.totalUnseenMessages = countNow;

        if (!numUpdate) {
          await numMessageupdate(displayText);
          setNumUpdate(true);
        }

        await updateValues(
          "POST",
          `conversations/${displayText}/${convId}/lastInformation/`,
          lastInfoObject
        );

        lastInfoObject.lastSeen.totalUnseenMessages = 0;

        await updateValues(
          "POST",
          `conversations/${userId}/${convId}/lastInformation/`,
          lastInfoObject
        );
      } else {
        const ids = userIdsOfMembers || [userId];

        for (const id of ids) {
          if (id !== userId) {
            let numMessage = await updateValues(
              "GET",
              `conversations/${id}/${convId}/lastInformation/lastSeen/`,
              null
            );

            let countNow = numMessage.data
              ? numMessage.data.totalUnseenMessages + 1
              : 1;

            lastInfoObject.lastSeen.totalUnseenMessages = countNow;
            lastInfoObject.lastSeen.lastMessageSeen = true;

            await updateValues(
              "POST",
              `conversations/${id}/${convId}/lastInformation/`,
              lastInfoObject
            );
          } else {
            lastInfoObject.lastSeen.totalUnseenMessages = 0;

            await updateValues(
              "POST",
              `conversations/${id}/${convId}/lastInformation/`,
              lastInfoObject
            );
          }
        }
      }
    }
  };

  const openImageModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  if (!selectedChat) {
    return (
      <div className="chatScreen">
        <p className="selectChatText">
          Please select a chat to start messaging
        </p>
      </div>
    );
  }

  return (
    <div className="chatScreen">
      <div className="chatScreen__header">
        <div
          className="chat-list-item-avatar-firstLetter"
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            height: "50px",
            width: "50px",
            fontWeight: "400",
            color: "black",
            fontSize: "20px",
            textTransform: "uppercase",
            fontFamily: "Montserrat",
          }}>
          {selectedChat?.name ? selectedChat.name[0] : "U"}
        </div>
        <h2 className="senderName">
          {selectedChat?.name ? selectedChat.name : "User"}
        </h2>
      </div>
      <div className="chatScreen__messages" ref={chatContainerRef}>
        {chatData.map((item, index) =>
          item.images != null ? (
            Object.values(item.images).map((image, index) => (
              <div
                key={index}
                className="message"
                style={{
                  alignSelf: item.userId == userId ? "flex-end" : "flex-start",
                  backgroundColor:
                    item.userId != userId ? "#E4E4E4" : "#fdd34d",
                  borderRadius: 10,
                  paddingHorizontal: 5,

                  marginRight: 10,
                  marginLeft: 10,
                  paddingVertical: 5,
                  padding: 10,
                  borderRadius: 10,
                  paddingHorizontal: 5,
                  marginRight: 10,
                  marginLeft: 10,
                  paddingVertical: 5,
                  padding: 10,
                  margin: "10px 10px 10px 10px",
                }}>
                <div className="message-sender">
                  {item.userId !== userId ? item.userName : "You"}
                </div>
                <div
                  className="imageContainer"
                  onClick={() => openImageModal(image.uri)}>
                  <img src={image.uri} alt="Selected" className="chatImage" />
                </div>
                <div className="message-timeStamp"> {item.time}</div>
                <div>{item.text}</div>
              </div>
            ))
          ) : item.type === "text" ? (
            <div
              key={index}
              className="message"
              style={{
                alignSelf: item.userId == userId ? "flex-end" : "flex-start",
                backgroundColor: item.userId != userId ? "#E4E4E4" : "#fdd34d",
                borderRadius: 10,
                paddingHorizontal: 5,

                marginRight: 10,
                marginLeft: 10,
                paddingVertical: 5,
                padding: 10,
              }}>
              <div className="message-sender">
                {item.userId !== userId ? item.userName : "You"}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <div>{item.text}</div>
                <div className="message-timeStamp"> {item.time}</div>
              </div>
            </div>
          ) : item.type === "transaction" ? (
            <div
              style={{
                alignSelf: item.userId == userId ? "flex-end" : "flex-start",
                backgroundColor: item.userId != userId ? "#E4E4E4" : "#fdd34d",
                borderRadius: 10,
                marginBottom: 10,
                marginRight: 10,
                marginLeft: 10,
                padding: 10,
                margin: "10px 10px 10px 10px",
                width: "25%",
              }}>
              <div className="message-sender">
                {item.userId !== userId ? item.userName : "You"}
              </div>
              <div
                style={{
                  backgroundColor: "#ffff",
                  padding: 10,
                  borderRadius: 10,
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.3)",
                }}>
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 5,
                  }}>
                  <p
                    style={{
                      flex: 1,
                      fontSize: 14,
                      fontFamily: "Montserrat",
                    }}>
                    {item.projectName ? item.projectName : "Project name"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}>
                  <p
                    style={{
                      fontSize: 16,
                      marginBottom: 8,
                      fontFamily: "Montserrat",
                      marginRight: 5,
                      maxWidth: "65%",
                      color: "black",
                      fontWeight: "bold",
                    }}>
                    {item.reason}
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      marginBottom: 8,
                      fontFamily: "Montserrat",
                      color: item.transactionType == "debit" ? "red" : "green",
                      fontWeight: "bold",
                    }}>
                    ₹{item.amount}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Montserrat",
                      marginBottom: 8,
                    }}>
                    {item.time}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Montserrat",
                      alignSelf: "flex-end",
                    }}>
                    #{item.tag}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 5,
                  }}>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Montserrat",
                      alignSelf: "flex-start",
                      textDecorationLine: "underline",
                      color: "blue",
                    }}>
                    {item.subEntries ? "Sub Entries" : ""}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignSelf: "flex-end",
                      marginTop: 5,
                    }}>
                    <p
                      style={{
                        fontSize: 12,
                        fontFamily: "Montserrat",
                        alignSelf: "flex-end",
                      }}>
                      By {item.userName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                borderBottomWidth: 0,
                backgroundColor: "#fff",
                padding: 10,
                margin: 5,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
                position: "relative",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 5,
                }}>
                <p
                  style={{
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    marginRight: 5,
                    maxWidth: "65%",
                    color: "black",
                  }}>
                  Project summary
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 5,
                }}>
                <p
                  style={{
                    flex: 1,
                    fontSize: 14,
                    fontFamily: "Montserrat",
                  }}>
                  {item.projectName ? item.projectName : "Project name"}
                </p>
              </div>

              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                }}>
                <p
                  style={{
                    color: "red",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  {item?.spent?.attribute?.name}
                </p>
                <p
                  style={{
                    color: "red",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  ₹ {item?.spent?.amount}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                }}>
                <p
                  style={{
                    color: "green",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  {item?.received?.attribute?.name}
                </p>
                <p
                  style={{
                    color: "green",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  ₹ {item?.received?.amount}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                }}>
                <p
                  style={{
                    color: "grey",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  {item?.balance?.attribute?.name}
                </p>
                <p
                  style={{
                    color: "black",
                    fontFamily: "Montserrat",
                    fontSize: 14,
                  }}>
                  ₹ {item?.balance?.amount}
                </p>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  marginBottom: 5,
                  alignSelf: "flex-end",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignSelf: "flex-end",
                    marginTop: 5,
                  }}>
                  <p
                    style={{
                      fontSize: 12,
                      fontFamily: "Montserrat",
                      alignSelf: "flex-end",
                    }}>
                    By {item?.user}
                  </p>
                </div>
              </div>
              <div>{item.text}</div>
              <div className="message-timeStamp">{item.time}</div>
            </div>
          )
        )}
      </div>
      <form
        className="chatScreen__input"
        onSubmit={(e) => {
          handleSend();
          e.preventDefault();
        }}>
        <div className="inputAndImage">
          <textarea
            type="text"
            placeholder="Type a message"
            placeholderFontFamily="Montserrat"
            className="input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <label htmlFor="fileInput" className="attachmentButton">
            <GrAttachment size={20} style={{ color: "black" }} />
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>
        </div>
        <button
          type="button"
          className="sendButton"
          onClick={() => handleSend()}
          disabled={isLoading}>
          {isLoading ? (
            <AiOutlineLoading3Quarters
              className="loading-spinner"
              size={30}
              style={{ color: "black" }}
            />
          ) : (
            <IoIosSend size={30} style={{ color: "black" }} />
          )}
        </button>
      </form>
      {selectedImage && (
        <Modal imageUrl={selectedImage} onClose={closeImageModal} />
      )}
    </div>
  );
};

export default Chat;
