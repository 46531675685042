import React, { useContext, useEffect, useState } from "react";
import classes from "./records.module.css";
import TransactionEntry from "./TransactionEntry";
import BalanceCard from "./BalanceCard";
import ReportCard from "./ReportCard";
import Entries from "./Entries";
import { projectContext, userIdContext } from "../../pages/Home";
import {
  getDatabase,
  set,
  ref,
  onValue,
  get,
  child,
  on,
  remove,
  off,
} from "firebase/database";
import { db } from "../../Firebase/config";
const database = getDatabase();
const Records = (props) => {
  const project = useContext(projectContext); // context coming from home component
  console.log("project id from records tab :", project);

  const { userNumber, userName } = useContext(userIdContext); // context coming from home component

  const { searchEntries, role, setRole, sharedPeople, tags } = props; //This is coming from project details page to search the entries of the project

  const [transactionEntryModalOpen, setTransctionEntryModalOpen] =
    useState(false);
  const [selectedBtn, setSelectedBtn] = useState();
  const [entriesData, setEntriesData] = useState({});

  const [selectedDataRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [allEntries, setAllEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState();
  const [displaySpent, setDisplaySpent] = useState(false);
  const [displayReceived, setDisplayReceived] = useState(false);

  const entrySelectionHandler = (entry) => {
    setTransctionEntryModalOpen(true);
    setSelectedBtn(entry.type === "debit" ? "spent" : "receive");
    setSelectedEntry(entry);
  };

  useEffect(() => {
    if (selectedDataRange.startDate || selectedDataRange.endDate) {
      setSelectedDateRange({
        startDate: null,
        endDate: null,
      });
    }
  }, [project]);

  return (
    <div className={classes.records_wrapper}>
      <div className={classes.balance_reports_wrapper}>
        <BalanceCard
          finance={project.finances}
          selectedDataRange={selectedDataRange}
          setSelectedDateRange={setSelectedDateRange}
          project={project}
          allEntries={allEntries}
          setAllEntries={setAllEntries}
          projectId={project.id}
          userId={userNumber}
          displayReceived={displayReceived}
          displaySpent={displaySpent}
          setDisplayReceived={setDisplayReceived}
          setDisplaySpent={setDisplaySpent}
          entriesData={entriesData}
          setEntriesData={setEntriesData}
        />
        <ReportCard
          userId={userNumber}
          projectId={project.id}
          searchEntries={searchEntries}
          entriesData={entriesData}
          setEntriesData={setEntriesData}
        />
      </div>

      <div>
        <Entries
          userId={userNumber}
          projectId={project.id}
          searchEntries={searchEntries}
          selectedDataRange={selectedDataRange}
          entrySelectionHandler={(data) => entrySelectionHandler(data)}
          project={project}
          sharedPeople={sharedPeople}
          userName={userName}
          allEntries={allEntries}
          setAllEntries={setAllEntries}
          displayReceived={displayReceived}
          displaySpent={displaySpent}
          setDisplayReceived={setDisplayReceived}
          setDisplaySpent={setDisplaySpent}
          role={role}
          setRole={setRole}
          entriesData={entriesData}
          setEntriesData={setEntriesData}
        />
      </div>
      {/* spent and receive btns UI */}
      {role !== "Viewer" ? (
        <div className={classes.mainBtns_wrapper}>
          <p
            className={classes.mainBtn}
            style={{ backgroundColor: "#c90000", marginRight: "10px" }}
            onClick={() => {
              setTransctionEntryModalOpen(true);
              setSelectedBtn("spent");
            }}
          >
            Spent
          </p>
          <p
            className={classes.mainBtn}
            style={{ backgroundColor: "#117539", marginLeft: "10px" }}
            onClick={() => {
              setTransctionEntryModalOpen(true);
              setSelectedBtn("receive");
            }}
          >
            Receive
          </p>
        </div>
      ) : null}
      {/* transaction entry modal */}

      <div>
        {transactionEntryModalOpen && (
          <TransactionEntry
            modalOpen={transactionEntryModalOpen}
            modalHandler={setTransctionEntryModalOpen}
            selectedBtn={selectedBtn}
            setSelectedBtn={setSelectedBtn}
            project={project}
            userId={userNumber}
            userName={userName}
            selectedEntry={selectedEntry}
            setSelectedEntry={setSelectedEntry}
            sharedPeople={sharedPeople}
            allTags={tags ? tags.tags : ""}
            role={role}
            setRole={setRole}
            entriesData={entriesData}
            setEntriesData={setEntriesData}
          />
        )}
      </div>
    </div>
  );
};

export default Records;
