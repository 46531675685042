import updateValues from "../../Firebase/firebase";
export async function numMessageupdate(userId) {
  console.log("number of the messages");
  let response = await updateValues(
    "GET",
    "userDetails/" + userId + "/numMessage/",
    null
  );
  if (response.data == null) {
    let count = 1;
    await updateValues("POST", "userDetails/" + userId + "/numMessage/", {
      count: count,
    });
  } else {
    let count = response.data.count + 1;
    await updateValues("POST", "userDetails/" + userId + "/numMessage/", {
      count: count,
    });
  }
}
