import classes from "./entryAttachments.module.css";

import Modal from "react-modal";

export default function EntryAttachments(props) {
  const { selectedAttachment, setSelectedAttachment } = props;

  return (
    <Modal
      isOpen={selectedAttachment.isSelected}
      onRequestClose={() => {
        setSelectedAttachment({ isSelected: false, details: {} });
      }}
      className={classes.content}
      overlayClassName={classes.modal_overLay}
      ariaHideApp={false}
    >
      {Object.keys(selectedAttachment.details["uploads"]).map((stamp) => {
        return (
          <div style={{ marginLeft: "10px", width: "100%", height: "100%" }}>
            <img
              src={selectedAttachment.details["uploads"][stamp]["uri"]}
              alt="img"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        );
      })}
    </Modal>
  );
}