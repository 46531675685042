import React from "react";

const Modal = ({ imageUrl, onClose }) => {
  return (
    <div className="imageModal" onClick={onClose}>
      <div className="modalContent">
        <span className="closeButton" onClick={onClose}>
          &times;
        </span>
        <img src={imageUrl} alt="Selected" className="modalImage" />
      </div>
    </div>
  );
};

export default Modal;