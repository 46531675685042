import { initializeApp } from "firebase/app";
import {
  getDatabase,
  set,
  ref,
  onValue,
  get,
  child,
  on,
  remove,
} from "firebase/database";
import { getStorage } from "firebase/storage";

// this is where we have written the credentials for the staart database
const database = getDatabase();
const defaultStorage = getStorage();
const updateValues = async (method, path, value) => {
  let response = {
    data: null,
    status: "failed",
  };
  if (method == "POST") {
    // write the code he

    console.log("started");
    await set(ref(database, path), value);
    console.log("ended");
    response.status = "Success";
    response.data = value;
  } else if (method == "GET") {
    // write the code here
    let values = {};
    const dbRef = ref(getDatabase());
    await get(child(dbRef, path))
      .then((snapshot) => {
        if (snapshot.exists()) {
          values = snapshot.val();
          response = {
            data: values,
            status: "Success",
          };
          // console.log('from firebase.js : ', response);
        } else {
          console.log("No data available");
          values = null;
          response = {
            data: values,
            status: "Failed",
          };
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } else if (method == "DEL") {
    // write the code here
    console.log("inside the DEL");
    const nodeRef = ref(getDatabase(), path);
    remove(nodeRef).then(() => {
      console.log("We have successfully removed the values");
    });
  }
  return response;
};

export default updateValues;
