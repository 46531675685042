import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import classes from "./TransactionEntry.module.css";
import { DatePicker } from "rc-datepicker";
import "rc-datepicker/lib/style.css";

import { IoMdCalendar } from "react-icons/io";
import { MdOutlineAttachFile } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import {
  deleteHandler,
  fileUploadHandler,
  transactionUpdate,
  userDetailsUpdate,
} from "../../api/api";
import { GrAttachment } from "react-icons/gr";
import Multiselect from "multiselect-react-dropdown";

let today_day = new Date().getDate();
let today_month = new Date().getMonth() + 1;
// today_month = today_month < 10 ? `0${today_month}` : today_month;
let today_year = new Date().getFullYear();

let today = `${today_day}-${today_month}-${today_year}`;

export default function TransactionEntry(props) {
  const {
    modalOpen,
    modalHandler,
    selectedBtn,
    setSelectedBtn,
    project,
    userId,
    userName,
    selectedEntry,
    setSelectedEntry,
    sharedPeople,
    allTags,
    tab,
    reqAmount, //coming from request.js component
  } = props;

  const attachementRef = useRef();

  const dropdownRef = useRef();

  const [enteredData, setEnteredData] = useState({
    amount: "",
    reason: "",
    tag: "",
    date: today,
    files: "",
  });

  const [date, setDate] = useState(new Date()); //we pass this into the calendar as a prop //we pass this into the calendar as a prop

  // const [selectedDate, setSelectedDate] = useState(today);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const [tempEntries, setTempEntries] = useState([]);

  const [tags, setTags] = useState();

  const [imgLoading, setImgLoading] = useState(false);

  const [isTagsFieldFocused, setIsTagsFieldFocused] = useState(false);

  const [peopleDropdown, setPeopleDropdown] = useState([]);

  const [selectedPeople, setSelectedPeople] = useState([]);

  useEffect(() => {
    if (sharedPeople) {
      let data = [];
      Object.keys(sharedPeople).forEach((person) => {
        let personName = sharedPeople[person]["name"];
        let num = sharedPeople[person]["number"];
        let role = sharedPeople[person]["role"];
        if (num !== userId && role !== "Viewer") {
          data.push({ name: personName, number: num, role: role });
        }
      });
      setPeopleDropdown(data);
    }
  }, [sharedPeople]);

  useEffect(() => {
    if (enteredData.tag && allTags) {
      let searchedTags = allTags
        ?.split(",")
        .filter((tag) =>
          tag.toLowerCase().includes(enteredData.tag.toLowerCase())
        );
      setTags(searchedTags);
    } else if (allTags) {
      setTags(allTags.split(","));
    } else {
      setTags([]);
    }
  }, [enteredData.tag]);

  useEffect(() => {
    if (selectedEntry) {
      let entryMakeDate;
      if (selectedEntry.date.includes("Last edited on")) {
        let actualMakeInDate = selectedEntry.date;
        let makeInDate = actualMakeInDate.slice(
          0,
          actualMakeInDate.indexOf(".")
        );
        entryMakeDate = makeInDate;
      } else {
        entryMakeDate = selectedEntry.date;
      }

      setEnteredData({
        amount: selectedEntry.amount,
        reason: selectedEntry.reason,
        tag: selectedEntry.tag,
        date: entryMakeDate,
        files: selectedEntry.uploads ? selectedEntry.uploads : null,
      });
    }
  }, [selectedEntry]);

  const entrySavingHandler = () => {
    let entryDate = enteredData.date;

    const [day, month, year] = entryDate.split("-");
    let hr = new Date().getHours();
    let min = new Date().getMinutes();
    let sec = new Date().getSeconds();

    let newDate = new Date(year, month - 1, day, hr, min, sec);
    let stamp = new Date(newDate).getTime();

    let finances = { ...project.finances };

    finances["balance"]["amount"] =
      selectedBtn === "spent"
        ? Number(finances["balance"]["amount"]) - Number(enteredData.amount)
        : Number(finances["balance"]["amount"]) + Number(enteredData.amount);

    if (selectedBtn === "spent") {
      finances["spent"]["amount"] =
        Number(finances["spent"]["amount"]) + Number(enteredData.amount);
    } else {
      finances["received"]["amount"] =
        Number(finances["received"]["amount"]) + Number(enteredData.amount);
    }

    if (sharedPeople) {
      Object.keys(sharedPeople).forEach((mobNo) => {
        let userDetails_financePath = `userDetails/${mobNo}/projects/${project.id}/finances`;
        userDetailsUpdate(userDetails_financePath, finances);
      });
    } else {
      let userDetails_financePath = `userDetails/${userId}/projects/${project.id}/finances`;
      userDetailsUpdate(userDetails_financePath, finances);
    }

    let financepath = `${project.id}/SE-pettycash/finances`;
    transactionUpdate(financepath, finances);

    let data = {
      [stamp]: {
        amount: enteredData.amount ? Number(enteredData.amount) : 0,
        balance: 0,
        date: enteredData.date,
        isEditing: false,
        reason: enteredData?.reason ? enteredData.reason : "None",
        tag: enteredData.tag ? enteredData.tag : "general",
        time: `${new Date(stamp).getHours()}:${new Date(stamp).getMinutes()}`,
        type: selectedBtn === "spent" ? "debit" : "credit",
        userName: userName,
        uploads: enteredData.files ? enteredData.files : null,
      },
    };

    let transactionPath = `${project.id}/SE-pettycash/records/transactionHistory`;
    transactionUpdate(transactionPath, data);

    const lastUpdatedData = {
      date: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: new Date().getTime(),
    };

    let lastUpdatedPath = `${project.id}/SE-pettycash/lastUpdated`;
    transactionUpdate(lastUpdatedPath, lastUpdatedData);
    setTempEntries([...tempEntries, data]);

    const lastTimeStamp = new Date().getTime();
    let userDetails_lastUpdatePath = `userDetails/${userId}/projects/${project.id}/`;
    userDetailsUpdate(userDetails_lastUpdatePath, {
      lastUpdated: lastTimeStamp,
    });

    //new tag checking here
    if (!allTags.split(",").includes(enteredData.tag)) {
      let tagdata = `${allTags},${enteredData.tag}`;
      let tagsPath = `userDetails/${userId}/myTags/`;
      userDetailsUpdate(tagsPath, { tags: tagdata });
    }

    setEnteredData({
      amount: "",
      reason: "",
      tag: "",
      date: today,
      files: "",
    });
  };

  const updateEntryHandler = () => {
    let finances = { ...project.finances };
    if (selectedEntry.type === "credit") {
      finances["balance"]["amount"] =
        Number(finances["balance"]["amount"]) -
        Number(selectedEntry.amount) +
        Number(enteredData.amount);

      finances["received"]["amount"] =
        Number(finances["received"]["amount"]) +
        Number(enteredData.amount) -
        Number(selectedEntry.amount);
    } else {
      finances["balance"]["amount"] =
        Number(finances["balance"]["amount"]) +
        Number(selectedEntry.amount) -
        Number(enteredData.amount);

      finances["spent"]["amount"] =
        Number(finances["spent"]["amount"]) +
        Number(enteredData.amount) -
        Number(selectedEntry.amount);
    }

    if (sharedPeople) {
      Object.keys(sharedPeople).forEach((mobNo) => {
        let userDetails_financePath = `userDetails/${mobNo}/projects/${project.id}/finances`;
        userDetailsUpdate(userDetails_financePath, finances);
      });
    } else {
      let userDetails_financePath = `userDetails/${userId}/projects/${project.id}/finances`;
      userDetailsUpdate(userDetails_financePath, finances);
    }

    let financepath = `${project.id}/SE-pettycash/finances`;
    transactionUpdate(financepath, finances);

    let timeStamp; // here we are checking the date of selected entry and enteredData.date to modify the time stamp of entry

    let entryMakeDate;
    if (selectedEntry.date.includes("Last edited on")) {
      let actualMakeInDate = selectedEntry.date;
      let makeInDate = actualMakeInDate.slice(0, actualMakeInDate.indexOf("."));
      entryMakeDate = makeInDate;
    } else {
      entryMakeDate = selectedEntry.date;
    }

    if (entryMakeDate === enteredData.date) {
      timeStamp = selectedEntry.stamp;
    } else {
      let entryDate = enteredData.date;

      const [day, month, year] = entryDate.split("-");
      let hr = new Date().getHours();
      let min = new Date().getMinutes();
      let sec = new Date().getSeconds();

      let newDate = new Date(year, month - 1, day, hr, min, sec);
      timeStamp = new Date(newDate).getTime();

      let entrydeletePath = `${project.id}/SE-pettycash/records/transactionHistory/${selectedEntry.stamp}`;
      deleteHandler(entrydeletePath);
    }

    let data = {
      [timeStamp]: {
        amount: Number(enteredData.amount),
        balance: 0,
        isEditing: false,
        reason: enteredData?.reason ? enteredData?.reason : "None",
        tag: enteredData.tag ? enteredData.tag : "general",
        time: `${new Date().getHours()}:${new Date().getMinutes()}`,
        type: selectedBtn === "spent" ? "debit" : "credit",
        userName: userName,
        uploads: enteredData.files ? enteredData.files : null,
      },
    };
    if (entryMakeDate !== enteredData.date) {
      data[timeStamp] = { ...data[timeStamp], date: enteredData.date };
    } else if (
      selectedEntry.amount === enteredData.amount &&
      selectedEntry.reason === enteredData.reason &&
      selectedEntry.tag === enteredData.tag
    ) {
      data[timeStamp] = { ...data[timeStamp], date: enteredData.date };
    } else {
      data[timeStamp] = {
        ...data[timeStamp],
        date: `${enteredData.date}.| Last edited on : ${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()}`,
      };
    }

    let transactionPath = `${project.id}/SE-pettycash/records/transactionHistory`;
    transactionUpdate(transactionPath, data);

    const lastUpdatedData = {
      date: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: new Date().getTime(),
    };

    let lastUpdatedPath = `${project.id}/SE-pettycash/lastUpdated`;
    transactionUpdate(lastUpdatedPath, lastUpdatedData);

    const lastTimeStamp = new Date().getTime();
    let userDetails_lastUpdatePath = `userDetails/${userId}/projects/${project.id}/`;
    userDetailsUpdate(userDetails_lastUpdatePath, {
      lastUpdated: lastTimeStamp,
    });

    if (
      selectedEntry.tag !== enteredData.tag &&
      !allTags.split(",").includes(enteredData.tag)
    ) {
      //new tag checking here
      let tagdata = `${allTags},${enteredData.tag}`;
      let tagsPath = `userDetails/${userId}/myTags/`;
      userDetailsUpdate(tagsPath, { tags: tagdata });
    }

    setEnteredData({
      amount: "",
      reason: "",
      tag: "",
      date: today,
      files: "",
    });

    setSelectedEntry();
    modalHandler(false);
  };

  const onChange = (jsDate) => {
    setDate(jsDate);
    let date = new Date(jsDate);
    let day = date.getDate();
    // day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    // month = month < 10 ? `0${month}` : month;
    let year = date.getFullYear();

    let selecDay = `${day}-${month}-${year}`;

    // setSelectedDate(selecDay);
    setCalendarOpen(false);
    setEnteredData({ ...enteredData, date: selecDay });
  };

  const attachementHandler = async (e) => {
    setImgLoading(true);
    let file = e.target.files[0];
    if (!file) {
      setImgLoading(false);
      alert("Please choose a file first!");
      return;
    }
    const cb = (data) => {
      if (data.status === "success") {
        let imageData = data;
        delete imageData.status;
        setEnteredData({
          ...enteredData,
          files: { ...enteredData.files, ...imageData },
        });
        setImgLoading(false);
      } else {
        console.log("Error while uploading the image");
        setImgLoading(false);
      }
    };

    fileUploadHandler(file, cb);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsTagsFieldFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    let data = [...selectedPeople, selectedItem];
    setSelectedPeople(data);
  };

  const onRemove = (selectedList, removedItem) => {
    let data = selectedPeople.filter(
      (person) => person.number !== removedItem.number
    );
    setSelectedPeople(data);
  };

  const requestMakingHandler = () => {
    let enteredAmount = enteredData.amount ? enteredData.amount : 0;

    let totalReqAmount = Number(reqAmount) + Number(enteredAmount);

    let reqPath = `${project.id}/SE-pettycash/request/requested`;
    userDetailsUpdate(reqPath, { amount: totalReqAmount });

    let timeStamp = new Date().getTime();

    const date = `${new Date().getDate()}-${
      new Date().getMonth() + 1
    }-${new Date().getFullYear()}`;

    let persons = {};

    let time = `${new Date().getHours()}:${new Date().getMinutes()}`;
    selectedPeople.forEach((person, index) => {
      timeStamp = timeStamp + index;
      persons[timeStamp] = person;
    });

    let requestData = {
      [new Date().getTime()]: {
        By: userName,
        date: date,
        status: "Requested",
        time: time,
        userId: userId,
      },
    };

    // console.log("request Date from request making handler :", requestData);

    let data = {
      [timeStamp]: {
        amount: enteredData.amount ? Number(enteredData.amount) : 0,
        associatedPeople: persons,
        balance: 0,
        date: date,
        reason: enteredData.reason ? enteredData.reason : "None",
        requestHistory: requestData,
        tag: enteredData.tag ? enteredData.tag : "general",
        time: time,
        type: selectedBtn === "spent" ? "debit" : "credit",
        userId: userId,
        userName: userName,
        uploads: enteredData.files ? enteredData.files : null,
      },
    };

    let transactionPath = `${project.id}/SE-pettycash/request/transactionHistory/`;
    userDetailsUpdate(transactionPath, data);

    setEnteredData({
      amount: "",
      reason: "",
      tag: "",
      date: today,
      files: "",
    });

    setSelectedPeople([]);
    modalHandler(false);
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => {
        if (isTagsFieldFocused) {
          setIsTagsFieldFocused(false);
        } else {
          modalHandler(false);
          if (selectedEntry) {
            setSelectedEntry();
          }
          setEnteredData({
            amount: "",
            reason: "",
            tag: "",
            date: today,
            files: "",
          });
        }
      }}
      className={
        tempEntries.length > 0 ? classes.content_width70 : classes.content
      }
      overlayClassName={classes.modal_overLay}
      ariaHideApp={false}
    >
      <div className={tempEntries.length > 0 ? classes.form_entries_class : ""}>
        <div style={{ width: tempEntries.length > 0 ? "45%" : "100%" }}>
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <div className={classes.mainBtns_wrapper}>
              <p
                className={classes.mainBtn}
                style={{
                  marginRight: "10px",
                  backgroundColor: selectedBtn === "spent" ? "#c90000" : "",
                  color: selectedBtn === "spent" ? "#fff" : "",
                  display:
                    selectedEntry && selectedEntry.type !== "debit"
                      ? "none"
                      : null,
                }}
                onClick={() => {
                  setSelectedBtn("spent");
                  if (selectedBtn !== "spent") {
                    setEnteredData({
                      amount: "",
                      reason: "",
                      tag: "",
                      date: today,
                      files: "",
                    });
                  }
                }}
              >
                Spent
              </p>

              <p
                className={classes.mainBtn}
                style={{
                  marginLeft: "10px",
                  backgroundColor: selectedBtn === "receive" ? "#117539" : "",
                  color: selectedBtn === "receive" ? "#fff" : "",
                  display:
                    selectedEntry && selectedEntry.type !== "credit"
                      ? "none"
                      : null,
                }}
                onClick={() => {
                  setSelectedBtn("receive");
                  if (selectedBtn !== "receive") {
                    setEnteredData({
                      amount: "",
                      reason: "",
                      tag: "",
                      date: today,
                      files: "",
                    });
                  }
                }}
              >
                Receive
              </p>
            </div>

            <div>
              <div
                className={`${classes.field_wrapper} ${classes.amount_calender_field}`}
              >
                <div>
                  <input
                    type="number"
                    className={`${classes.entryField} ${classes.amountInput}`}
                    onChange={(e) =>
                      setEnteredData({ ...enteredData, amount: e.target.value })
                    }
                    value={enteredData.amount}
                  />
                  <p className={classes.inputLabel}>Enter Amount</p>
                </div>
                <div className={classes.date_calender_Icon}>
                  <p className={classes.selectedData_class}>
                    {enteredData.date}
                  </p>
                  <IoMdCalendar
                    size={30}
                    onClick={() => setCalendarOpen(!calendarOpen)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              {calendarOpen && (
                <div className={classes.calendar_wrapper}>
                  <DatePicker
                    onChange={onChange}
                    value={date}
                    className="my-react-datepicker"
                    minDate={
                      tab === "requests"
                        ? new Date().toISOString().split("T")[0]
                        : null
                    }
                  />
                </div>
              )}
              <div className={classes.field_wrapper}>
                <input
                  type="text"
                  className={classes.entryField}
                  onChange={(e) =>
                    setEnteredData({ ...enteredData, reason: e.target.value })
                  }
                  value={enteredData.reason}
                />
                <p className={classes.inputLabel}>Reason</p>
              </div>
              <div className={classes.field_wrapper} ref={dropdownRef}>
                <input
                  type="text"
                  className={classes.entryField}
                  style={{ paddingLeft: "30px" }}
                  onChange={(e) => {
                    setEnteredData({ ...enteredData, tag: e.target.value });
                  }}
                  value={enteredData.tag}
                  onFocus={() => {
                    setIsTagsFieldFocused(true);
                  }}
                />
                <p className={classes.inputLabel}>Add tag</p>
                <span>#</span>

                <>
                  {tags && isTagsFieldFocused && (
                    <div
                      className={classes.tags_wrapper}
                      style={{ height: tags.length > 0 ? "15vh" : 0 }}
                    >
                      {tags.map((tag, index) => {
                        return (
                          <div
                            key={index}
                            className={classes.tagName}
                            onClick={() => {
                              setEnteredData({
                                ...enteredData,
                                tag: tag,
                              });
                              setIsTagsFieldFocused(false);
                            }}
                          >
                            <p>{tag}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              </div>

              {tab === "requests" && (
                <div
                  style={{
                    width: "26vw",
                    marginBottom: "20px",
                  }}
                >
                  <Multiselect
                    options={peopleDropdown}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                    showCheckbox
                    style={{
                      multiselectContainer: {
                        width: "100%",
                      },
                      inputField: {
                        height: "25px",
                      },
                      searchBox: {
                        borderRadius: "8px",
                        border: "2px solid lightgrey",
                        fontSize: "8px",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        overflowY: "scroll",
                      },
                      optionContainer: {
                        height: "20vh",
                      },
                    }}
                  />
                </div>
              )}

              <>
                <input
                  type="file"
                  ref={attachementRef}
                  onChange={attachementHandler}
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg,"
                />

                <div>
                  <MdOutlineAttachFile
                    size={25}
                    onClick={() => {
                      if (!imgLoading) {
                        attachementRef.current.click();
                      }
                    }}
                  />
                </div>
              </>

              {enteredData?.files && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "scroll",
                    scrollbarWidth: "thin",
                  }}
                >
                  {Object.keys(enteredData.files).map((imgStamp) => {
                    return (
                      <div key={imgStamp}>
                        <img
                          src={enteredData.files[imgStamp]["uri"]}
                          alt="image"
                          style={{
                            width: "60px",
                            height: "60px",
                            objectFit: "contain",
                            margin: "5px 15px",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              <div className={classes.cls_addentry_btns}>
                <button
                  className={classes.clsBtn}
                  onClick={() => {
                    modalHandler(false);
                    if (selectedEntry) {
                      setSelectedEntry();
                    }
                    setEnteredData({
                      amount: "",
                      reason: "",
                      tag: "",
                      date: today,
                      files: "",
                    });
                  }}
                >
                  Close
                </button>
                <div
                  className={classes.addEntryBtn_imgSpinner_class}
                  onClick={() => {
                    if (tab === "requests") {
                      if (selectedPeople.length > 0) {
                        requestMakingHandler();
                      } else {
                        alert("Please select the person");
                      }
                    } else {
                      if (selectedEntry) {
                        updateEntryHandler();
                      } else {
                        entrySavingHandler();
                      }
                    }
                  }}
                  disabled={imgLoading ? true : false}
                >
                  <div type="submit" className={classes.addEntryBtn}>
                    {imgLoading
                      ? ""
                      : tab === "requests"
                      ? "Request"
                      : selectedEntry
                      ? "Update entry"
                      : "Add entry"}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                    }}
                  >
                    <Oval
                      visible={imgLoading}
                      height="25"
                      width="25"
                      color=" #fff"
                      secondaryColor="lightgray"
                      ariaLabel="oval-loading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={tempEntries.length > 0 ? classes.entriesWrapper : ""}>
          {tempEntries.length > 0 &&
            tempEntries.map((entry, index) => {
              return (
                <div key={index} className={classes.card}>
                  <div className={classes.flex_wrapper}>
                    <p
                      className={classes.reason_amount_font}
                      style={{ flex: 0.6, flexWrap: "nowrap" }}
                    >
                      {entry[Object.keys(entry)[0]]["reason"]}
                    </p>
                    <div className={classes.attachmentIcon_amount_class}>
                      {entry[Object.keys(entry)[0]]["uploads"] && (
                        <GrAttachment />
                      )}
                      <p
                        className={classes.reason_amount_font}
                        style={{
                          color:
                            entry[Object.keys(entry)[0]]["type"] === "debit"
                              ? "#c90000"
                              : "#117539",
                        }}
                      >
                        {entry[Object.keys(entry)[0]]["amount"]}
                      </p>
                    </div>
                  </div>
                  <div className={classes.flex_wrapper}>
                    <p className={classes.date_class}>
                      {entry[Object.keys(entry)[0]]["date"]} <span>|</span>
                      {""} {entry[Object.keys(entry)[0]]["time"]}
                    </p>
                    <div className={classes.tag}>
                      <span>
                        {entry[Object.keys(entry)[0]]["userName"]} {""}
                      </span>
                      <span>#{entry[Object.keys(entry)[0]]["tag"]}</span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
}
