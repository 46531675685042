import React, { useState, useContext, useEffect } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import classes from "./Request.module.css";
import { DatePicker } from "antd";
import { IoMdCalendar } from "react-icons/io";
import RequestCard from "./RequestCard";
import { projectContext, userIdContext } from "../../pages/Home";
import { onValue, ref } from "firebase/database";
import { db } from "../../Firebase/config";
import { Oval } from "react-loader-spinner";
import {
  userDetailsUpdate,
  transactionUpdate,
  deleteHandler,
} from "../../api/api";
import TransactionEntry from "../RecordTab/TransactionEntry";

const { RangePicker } = DatePicker;
const items = ["All requests", "Sent requests", "Received requests"];

export default function Request(props) {
  const { searchEntries, sharedPeople, tags, role } = props;
  console.log("shared people from request component :", sharedPeople);

  console.log("role from request js page :", role);

  const project = useContext(projectContext); // context coming from home component

  const { userNumber, userName } = useContext(userIdContext); // context coming from home component

  const [calendarDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [dateRange, setDateRange] = useState([]);

  const [requestData, setRequestData] = useState({});

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [requestFilter, setRequestFilter] = useState("All requests");

  const [transactionEntryModalOpen, setTransctionEntryModalOpen] =
    useState(false);

  const [selectedBtn, setSelectedBtn] = useState();

  useEffect(() => {
    setRequestFilter("All requests");
    if (calendarDateRange.startDate || calendarDateRange.endDate) {
      setDateRange([]);
      setSelectedDateRange({
        startDate: null,
        endDate: null,
      });
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      setLoadingSpinner(true);
      let path = `${project.id}/SE-pettycash/request`;
      onValue(
        ref(db, path),
        (snapShot) => {
          if (snapShot.exists()) {
            let data = snapShot.val();

            if (data.transactionHistory) {
              if (requestFilter === "All requests") {
                // if date filter is applied
                if (calendarDateRange.startDate && calendarDateRange.endDate) {
                  let dataInDateRange = {};
                  Object.keys(data.transactionHistory).forEach(
                    (transactionId) => {
                      let dueDate =
                        data.transactionHistory[transactionId]["date"].split(
                          "-"
                        );
                      let day =
                        Number(dueDate[0]) < 10 ? `0${dueDate[0]}` : dueDate[0];
                      let month =
                        Number(dueDate[1]) < 10 ? `0${dueDate[1]}` : dueDate[1];
                      let year = dueDate[2];

                      dueDate = Number(`${year}${month}${day}`);
                      if (
                        Number(calendarDateRange.startDate) <= dueDate &&
                        dueDate <= Number(calendarDateRange.endDate)
                      ) {
                        dataInDateRange[transactionId] =
                          data.transactionHistory[transactionId];
                      }
                    }
                  );
                  data.transactionHistory = dataInDateRange;
                  setRequestData(data);
                  setLoadingSpinner(false);
                } else {
                  setRequestData(data);
                  setLoadingSpinner(false);
                }
              } else if (requestFilter === "Received requests") {
                receivedRequestHandler(data);
                setLoadingSpinner(false);
              } else if (requestFilter === "Sent requests") {
                sentRequestHandler(data);
                setLoadingSpinner(false);
              }
            } else {
              setRequestData(data);
              setLoadingSpinner(false);
            }
          } else {
            console.log("No entries were found");
            setRequestData({});
            setLoadingSpinner(false);
          }
        },
        (error) => {
          console.log("error while fetching request data :", error);
          setLoadingSpinner(false);
        }
      );
    }
  }, [project, requestFilter, calendarDateRange]);

  const receivedRequestHandler = (data) => {
    let requestedData = {};
    Object.keys(data.transactionHistory).forEach((transactionId) => {
      let reqHistory = data.transactionHistory[transactionId]["requestHistory"];
      let lastHistory =
        Object.keys(reqHistory)[Object.keys(reqHistory).length - 1];

      //if date range filter applied
      if (calendarDateRange.startDate && calendarDateRange.endDate) {
        let dueDate = data.transactionHistory[transactionId]["date"].split("-");
        let day = Number(dueDate[0]) < 10 ? `0${dueDate[0]}` : dueDate[0];
        let month = Number(dueDate[1]) < 10 ? `0${dueDate[1]}` : dueDate[1];
        let year = dueDate[2];

        dueDate = Number(`${year}${month}${day}`);

        if (
          Number(calendarDateRange.startDate) <= dueDate &&
          dueDate <= Number(calendarDateRange.endDate)
        ) {
          if (
            data.transactionHistory[transactionId]["requestHistory"][
              lastHistory
            ]["status"] === "Requested" &&
            userNumber !==
              data.transactionHistory[transactionId]["requestHistory"][
                lastHistory
              ]["userId"]
          ) {
            requestedData[transactionId] =
              data.transactionHistory[transactionId];
          }
        }
      } else if (
        data.transactionHistory[transactionId]["requestHistory"][lastHistory][
          "status"
        ] === "Requested" &&
        userNumber !==
          data.transactionHistory[transactionId]["requestHistory"][lastHistory][
            "userId"
          ]
      ) {
        requestedData[transactionId] = data.transactionHistory[transactionId];
      }
    });
    data.transactionHistory = requestedData;

    setRequestData(data);
  };

  const sentRequestHandler = (data) => {
    let requestedData = {};
    Object.keys(data.transactionHistory).forEach((transactionId) => {
      let reqHistory = data.transactionHistory[transactionId]["requestHistory"];
      let lastHistory = Object.keys(reqHistory)[0];

      if (calendarDateRange.startDate && calendarDateRange.endDate) {
        let dueDate = data.transactionHistory[transactionId]["date"].split("-");
        let day = Number(dueDate[0]) < 10 ? `0${dueDate[0]}` : dueDate[0];
        let month = Number(dueDate[1]) < 10 ? `0${dueDate[1]}` : dueDate[1];
        let year = dueDate[2];

        dueDate = Number(`${year}${month}${day}`);

        if (
          Number(calendarDateRange.startDate) <= dueDate &&
          dueDate <= Number(calendarDateRange.endDate)
        ) {
          if (
            data.transactionHistory[transactionId]["requestHistory"][
              lastHistory
            ]["status"] === "Requested" &&
            userNumber ===
              data.transactionHistory[transactionId]["requestHistory"][
                lastHistory
              ]["userId"]
          ) {
            requestedData[transactionId] =
              data.transactionHistory[transactionId];
          }
        }
      } else if (
        data.transactionHistory[transactionId]["requestHistory"][lastHistory][
          "status"
        ] === "Requested" &&
        userNumber ===
          data.transactionHistory[transactionId]["requestHistory"][lastHistory][
            "userId"
          ]
      ) {
        requestedData[transactionId] = data.transactionHistory[transactionId];
      }
    });
    data.transactionHistory = requestedData;

    setRequestData(data);
  };

  const statusChangeHandler = (data, updatedStatus) => {
    const cardStamp = Object.keys(data)[0];

    const stamp = new Date().getTime();

    const requestHistory = data[cardStamp]["requestHistory"];

    const lastHistoryKey =
      Object.keys(requestHistory)[Object.keys(requestHistory).length - 1];

    const lastHistoryStatus = requestHistory[lastHistoryKey]["status"];

    if (updatedStatus === "Approve" && lastHistoryStatus !== "Approved") {
      let approvedAmount =
        Number(requestData.approved?.["amount"]) +
        Number(data[cardStamp]["amount"]);

      let path = `${project.id}/SE-pettycash/request/approved`;
      let result = { amount: approvedAmount };
      userDetailsUpdate(path, result);

      if (lastHistoryStatus === "Requested") {
        let requestedAmount =
          Number(requestData.requested?.["amount"]) -
          Number(data[cardStamp]["amount"]);
        let path = `${project.id}/SE-pettycash/request/requested`;
        let result = { amount: requestedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "Declined") {
        let declinedAmount =
          Number(requestData.declined?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/declined`;
        let result = { amount: declinedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "On hold") {
        let onHoldAmount =
          Number(requestData.onHold?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/onHold`;
        let result = { amount: onHoldAmount };
        userDetailsUpdate(path, result);
      }
    } else if (
      updatedStatus === "Decline" &&
      lastHistoryStatus !== "Declined"
    ) {
      let declinedAmount =
        Number(requestData.declined?.["amount"]) +
        Number(data[cardStamp]["amount"]);

      let path = `${project.id}/SE-pettycash/request/declined`;
      let result = { amount: declinedAmount };
      userDetailsUpdate(path, result);

      if (lastHistoryStatus === "Requested") {
        let requestedAmount =
          Number(requestData.requested?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/requested`;
        let result = { amount: requestedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "Approved") {
        let approvedAmount =
          Number(requestData.approved?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/approved`;
        let result = { amount: approvedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "On hold") {
        let onHoldAmount =
          Number(requestData.onHold?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/onHold`;
        let result = { amount: onHoldAmount };
        userDetailsUpdate(path, result);
      }
    } else if (updatedStatus === "On hold" && lastHistoryStatus !== "On hold") {
      let onHoldAmount =
        Number(requestData.onHold?.["amount"]) +
        Number(data[cardStamp]["amount"]);
      let path = `${project.id}/SE-pettycash/request/onHold`;
      let result = { amount: onHoldAmount };
      userDetailsUpdate(path, result);

      if (lastHistoryStatus === "Requested") {
        let requestedAmount =
          Number(requestData.requested?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/requested`;
        let result = { amount: requestedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "Declined") {
        let declinedAmount =
          Number(requestData.declined?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/declined`;
        let result = { amount: declinedAmount };
        userDetailsUpdate(path, result);
      } else if (lastHistoryStatus === "Approved") {
        let approvedAmount =
          Number(requestData.approved?.["amount"]) -
          Number(data[cardStamp]["amount"]);

        let path = `${project.id}/SE-pettycash/request/approved`;
        let result = { amount: approvedAmount };
        userDetailsUpdate(path, result);
      }
    }

    const newEntry = {
      [stamp]: {
        By: userName,
        date: `${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()}`,
        status:
          updatedStatus === "Approve"
            ? "Approved"
            : updatedStatus === "Decline"
            ? "Declined"
            : "On hold",
        time: `${new Date().getHours()}:${new Date().getMinutes()}`,
        userId: userNumber,
      },
    };

    let path = `${project.id}/SE-pettycash/request/transactionHistory/${cardStamp}/requestHistory`;
    userDetailsUpdate(path, newEntry);
  };

  const requestUpdateHandler = (cardData) => {
    newEntryInRecordsHandler(cardData);
    removingEntryFromRequest(cardData);
  };

  const newEntryInRecordsHandler = (cardData) => {
    const cardStamp = Object.keys(cardData)[0];

    const finances = { ...project.finances };

    finances["balance"]["amount"] =
      cardData[cardStamp]["type"] === "debit"
        ? Number(finances["balance"]["amount"]) -
          Number(cardData[cardStamp]["amount"])
        : Number(finances["balance"]["amount"]) +
          Number(cardData[cardStamp]["amount"]);

    if (cardData[cardStamp]["type"] === "debit") {
      finances["spent"]["amount"] =
        Number(finances["spent"]["amount"]) +
        Number(cardData[cardStamp]["amount"]);
    } else {
      finances["received"]["amount"] =
        Number(finances["received"]["amount"]) +
        Number(cardData[cardStamp]["amount"]);
    }

    if (sharedPeople) {
      Object.keys(sharedPeople).forEach((mobNo) => {
        let userDetails_financePath = `userDetails/${mobNo}/projects/${project.id}/finances`;
        userDetailsUpdate(userDetails_financePath, finances);
      });
    } else {
      let userDetails_financePath = `userDetails/${userNumber}/projects/${project.id}/finances`;
      userDetailsUpdate(userDetails_financePath, finances);
    }

    let financepath = `${project.id}/SE-pettycash/finances`;
    transactionUpdate(financepath, finances);

    let data = cardData;

    data[cardStamp]["userName"] = userName;

    let transactionPath = `${project.id}/SE-pettycash/records/transactionHistory`;
    transactionUpdate(transactionPath, data);

    const lastUpdatedData = {
      date: `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`,
      time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
      timeStamp: new Date().getTime(),
    };

    let lastUpdatedPath = `${project.id}/SE-pettycash/lastUpdated`;
    transactionUpdate(lastUpdatedPath, lastUpdatedData);

    const lastTimeStamp = new Date().getTime();
    let userDetails_lastUpdatePath = `userDetails/${userNumber}/projects/${project.id}/`;
    userDetailsUpdate(userDetails_lastUpdatePath, {
      lastUpdated: lastTimeStamp,
    });
  };

  const removingEntryFromRequest = (cardData) => {
    const cardStamp = Object.keys(cardData)[0];

    let approvedAmount =
      Number(requestData.approved?.["amount"]) -
      Number(cardData[cardStamp]["amount"]);

    let path = `${project.id}/SE-pettycash/request/approved`;
    let result = { amount: approvedAmount };
    userDetailsUpdate(path, result);

    let entrydeletePath = `${project.id}/SE-pettycash/request/transactionHistory/${cardStamp}`;
    deleteHandler(entrydeletePath);
  };

  const loadingHandler = () => {
    return (
      <Oval
        visible={loadingSpinner}
        height="20"
        width="20"
        color=" black"
        secondaryColor="lightgray"
        ariaLabel="oval-loading"
      />
    );
  };

  return (
    <div>
      <div className={classes.topcontainer}>
        <div
          className={classes.dropDown_headings}
          style={{ paddingBottom: "15px" }}>
          <div className={classes.left_elemets}>
            <Dropdown
              options={items}
              onChange={(item) => setRequestFilter(item.value)}
              value={requestFilter}
            />
          </div>

          <div className={classes.topcontainer_headings}>
            <div className={classes.flex_row}>
              <p>Declined</p>
              {loadingSpinner ? (
                loadingHandler()
              ) : (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  {requestData.declined?.["amount"]}
                </p>
              )}
            </div>
            <div className={classes.flex_row}>
              <p>On hold</p>
              {loadingSpinner ? (
                loadingHandler()
              ) : (
                <p>{requestData.onHold?.["amount"]}</p>
              )}
            </div>
          </div>
        </div>

        <div className={classes.dropDown_headings}>
          <div className={classes.left_elemets}>
            <div
              className={classes.icon_text_aligner}
              onClick={() => {
                setSelectedDateRange({ startDate: null, endDate: null });
              }}>
              <IoMdCalendar size={20} className={classes.calendarIcon} />
              <p>Select dates</p>
            </div>
          </div>

          <div
            className={classes.topcontainer_headings}
            style={{ position: "relative", top: "10px" }}>
            <div className={classes.flex_row}>
              <p>Approved</p>
              {loadingSpinner ? (
                loadingHandler()
              ) : (
                <p style={{ color: "#117539", fontWeight: "bold" }}>
                  {requestData.approved?.["amount"]}
                </p>
              )}
            </div>
            <div className={classes.flex_row}>
              <p>Requested</p>
              {loadingSpinner ? (
                loadingHandler()
              ) : (
                <p>{requestData.requested?.["amount"]}</p>
              )}
            </div>
          </div>
        </div>

        <RangePicker
          value={dateRange}
          onChange={(dates, dateStrings) => {
            if (dates) {
              setDateRange(dates);
              let [start, end] = dateStrings;
              setSelectedDateRange({
                startDate: start.replaceAll("-", ""),
                endDate: end.replaceAll("-", ""),
              });
            } else {
              setSelectedDateRange({
                startDate: null,
                endDate: null,
              });
            }
          }}
        />
      </div>

      {loadingSpinner ? (
        <div className={classes.spinner}>
          <Oval
            visible={loadingSpinner}
            height="80"
            width="80"
            color=" #fdd34d"
            secondaryColor="lightgray"
            ariaLabel="oval-loading"
          />
        </div>
      ) : (
        <div className={classes.cards_wrapper}>
          {requestData.transactionHistory &&
            Object.keys(requestData.transactionHistory)
              .reverse()
              .map((transactionId, index) => {
                return requestData.transactionHistory[transactionId]["reason"]
                  ?.toLowerCase()
                  .includes(searchEntries.toLowerCase()) ? (
                  <div key={index}>
                    <RequestCard
                      cardData={{
                        [transactionId]:
                          requestData.transactionHistory[transactionId],
                      }}
                      statusChangeHandler={statusChangeHandler}
                      role={role}
                      requestUpdateHandler={requestUpdateHandler}
                      userNumber={userNumber}
                    />
                  </div>
                ) : null;
              })}
          <div>
            <div
              className={classes.no_data}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                color: "#333",
              }}>
              <p>End of requests</p>
            </div>
          </div>
          {role !== "Viewer" && (
            <>
              {/* // spent and receive btns UI */}
              <div className={classes.mainBtns_wrapper}>
                <p
                  className={classes.mainBtn}
                  style={{
                    backgroundColor: "#c90000",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    if (sharedPeople) {
                      setTransctionEntryModalOpen(true);
                      setSelectedBtn("spent");
                    } else {
                      alert("No person is added in to the project");
                    }
                  }}>
                  Spent
                </p>
                <p
                  className={classes.mainBtn}
                  style={{ backgroundColor: "#117539", marginLeft: "10px" }}
                  onClick={() => {
                    if (sharedPeople) {
                      setTransctionEntryModalOpen(true);
                      setSelectedBtn("receive");
                    } else {
                      alert("No person is added in to the project");
                    }
                  }}>
                  Receive
                </p>
              </div>
              <div>
                {transactionEntryModalOpen && (
                  <TransactionEntry
                    modalOpen={transactionEntryModalOpen}
                    modalHandler={setTransctionEntryModalOpen}
                    selectedBtn={selectedBtn}
                    setSelectedBtn={setSelectedBtn}
                    project={project}
                    userId={userNumber}
                    userName={userName}
                    sharedPeople={sharedPeople}
                    allTags={tags ? tags.tags : ""}
                    tab="requests"
                    reqAmount={requestData?.requested?.["amount"]}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
