import React, { useEffect, useState } from "react";
import classes from "./ReportCard.module.css";
import { fetchEntries } from "../../api/api";
import { filterEntriesAndCalculateBalance } from "./utils";
import { FaAngleRight } from "react-icons/fa6";

export default function ReportCard(props) {
  const { userId, projectId, searchEntries } = props;
  const [reportData, setReportData] = useState([]);
  const [entriesData, setEntriesData] = useState({});

  useEffect(() => {
    if (projectId && userId) {
      const retrieveEntries = (data) => {
        setEntriesData(data);
      };
      const getEntries = async () => {
        try {
          let path = `${projectId}/SE-pettycash/records/transactionHistory`;
          await fetchEntries(path, retrieveEntries);
        } catch (error) {
          console.log("error on entries page :", error);
        }
      };
      getEntries();
    }
  }, [projectId, userId]);

  useEffect(() => {
    const topTags = filterEntriesAndCalculateBalance(entriesData);
    setReportData(topTags);
  }, [entriesData]);

  return (
    <div className={classes.reportsCard}>
      <div className={classes.headingsWrapper}>
        <p className={classes.resportsHeading}>Reports</p>
        <div className={classes.dashBoardWrapper}>
          <p className={classes.resportsHeading}>DashBoard</p>
          <span>
            <FaAngleRight size={20} />
          </span>
        </div>
      </div>
      <div className={classes.reportsdata_wrapper}>
        {reportData.map((data, index) => (
          <div key={index} className={classes.data_wrapper}>
            <p className={classes.data_tag}>{data.tag}</p>
            <p className={classes.data_percentage}>{data.percentage}%</p>
            <p
              className={`${classes.data_balance} ${
                data.balance < 0 ? classes.negative : classes.positive
              }`}>
              {data.balance}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
