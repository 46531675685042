import { useEffect, useState, useRef } from "react";
import classes from "./projects.module.css";
import { userDetailsUpdate } from "../../api/api";

import { GoSearch } from "react-icons/go";

export default function Projects(props) {
  const {
    selectedProject,
    setSelectedProject,
    allProjects,
    userId,
    role,
    setRole,
  } = props;

  const containerRef = useRef(null);

  const [searchedProject, setSearchedproject] = useState("");

  useEffect(() => {
    if (selectedProject) {
      let [updatedDetails] = allProjects.filter(
        (project) => project.id === selectedProject.id
      );
      setSelectedProject(updatedDetails);
    }
  }, [allProjects, selectedProject]);

  // const lastUpdateHandler = (project) => {
  //   const lastTimeStamp = new Date().getTime();
  //   let userDetails_lastUpdatePath = `userDetails/${userId}/projects/${project.id}/`;
  //   userDetailsUpdate(userDetails_lastUpdatePath, {
  //     lastUpdated: lastTimeStamp,
  //   });
  //   setSearchedproject("");
  // };

  return (
    <>
      <div className={classes.inputWrapper}>
        <input
          type="text"
          placeholder="Search..."
          className={classes.searchInputTab}
          onChange={(e) => setSearchedproject(e.target.value)}
          value={searchedProject}
        />
        <GoSearch style={{ marginLeft: "10px" }} />
      </div>

      <div className={classes.projects_wrapper} ref={containerRef}>
        <div>
          {allProjects.length > 0 &&
            allProjects.map((project, index) => {
              return project.name
                ?.toLowerCase()
                .includes(searchedProject?.toLowerCase()) ? (
                <div
                  key={project.id}
                  className={
                    selectedProject?.id === project.id
                      ? classes.selectedProject_card
                      : classes.project_card
                  }
                  onClick={() => {
                    setSelectedProject(project);
                    // lastUpdateHandler(project);
                    // containerRef.current.scrollTop = 0;
                  }}>
                  <p className={classes.name_class}>{project.name}</p>
                  <p className={classes.role_class}>
                    Role : <span>{project.role}</span>
                  </p>
                  <div className={classes.finances_class}>
                    <div className={classes.subFinance_class}>
                      <p className={classes.finances_heading}>Spent</p>
                      <p
                        style={{ color: "#c90000" }}
                        className={classes.finances_keys}>
                        {project.finances.spent["amount"]}
                      </p>
                    </div>
                    <div className={classes.subFinance_class}>
                      <p className={classes.finances_heading}>Received</p>
                      <p
                        style={{ color: "#117539" }}
                        className={classes.finances_keys}>
                        {project.finances.received["amount"]}
                      </p>
                    </div>
                    <div className={classes.subFinance_class}>
                      <p className={classes.finances_heading}>Balance</p>
                      <p className={classes.finances_keys}>
                        {project.finances.balance["amount"]}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null;
            })}
        </div>
      </div>
    </>
  );
}
